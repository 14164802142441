import React, { useCallback, useEffect, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Input, Switch, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { ChannelData } from '@/Interfaces/I_vod';

import { updateChannels } from '@/Redux/slices/VodSlice';

import CreateChannelModal from '@/Components/CreateChannelModal';

import type { DragEndEvent } from '@dnd-kit/core';

interface ChannelUI {
  id: number;
  priority: number;
  mallCategoryName: string;
  name: string;
  disabled: boolean;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ChannelPriority = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.vod.loading);
  const channels = useAppSelector((state) => state.vod.value.channels);

  const [pristine, setPristine] = useState(true);
  const [channelUIs, setChannelUIs] = useState<ChannelUI[]>([]);
  const [editingChannel, setEditingChannel] = useState<ChannelUI | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const initChannelUIs = useCallback(() => {
    const sortByPriority = channels
      .toSorted((c1: ChannelData, c2: ChannelData) => c1.priority - c2.priority)
      .map((channel) => ({
        id: channel.channelId,
        priority: channel.priority,
        name: channel.channelName,
        disabled: channel.disabled,
        mallCategoryName: channel.mallCategoryName || '',
      }));

    setChannelUIs(sortByPriority);
    setEditingChannel(null);
    setPristine(true);
  }, [channels]);

  useEffect(() => {
    if (!loading && channels.length > 0) {
      initChannelUIs();
    }
  }, [initChannelUIs, loading, channels]);

  const onChannelUIEditConfirmed = useCallback(() => {
    let updated = false;
    const updatedChannelUIs = channelUIs.map((channel) => {
      if (channel.id === editingChannel?.id) {
        updated =
          channel.name !== editingChannel.name || channel.disabled !== editingChannel.disabled;
        return { ...editingChannel };
      }
      return channel;
    });

    setChannelUIs(updatedChannelUIs);
    setEditingChannel(null);
    setPristine((prev) => !updated && prev);
  }, [channelUIs, editingChannel]);

  const columns: ColumnsType<ChannelUI> = [
    {
      key: 'sort',
    },
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
    },
    {
      title: 'Channel',
      dataIndex: 'name',
      render: (_, channelUI) =>
        editingChannel?.id === channelUI.id ? (
          <Input
            defaultValue={channelUI.name}
            onChange={(e) => setEditingChannel({ ...channelUI, name: e.target.value })}
          />
        ) : (
          channelUI.name
        ),
      width: '40%',
    },
    {
      title: 'Street',
      dataIndex: 'mallCategoryName',
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      render: (_, channelUI) =>
        channelUI.id === editingChannel?.id ? (
          <Switch
            disabled={editingChannel?.id !== channelUI.id}
            defaultChecked={channelUI.disabled}
            onChange={() =>
              setEditingChannel((prev) => ({ ...prev, disabled: !prev?.disabled } as ChannelUI))
            }
          />
        ) : (
          <Switch
            disabled={editingChannel?.id !== channelUI.id}
            checked={channelUI.disabled}
            onChange={() =>
              setEditingChannel((prev) => ({ ...prev, disabled: !prev?.disabled } as ChannelUI))
            }
          />
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, channelUI) =>
        editingChannel?.id === channelUI.id ? (
          <span>
            <Button onClick={() => onChannelUIEditConfirmed()} style={{ marginRight: 8 }}>
              Confirm
            </Button>
            <Button onClick={() => setEditingChannel(null)}>Cancel</Button>
          </span>
        ) : (
          <Typography.Link onClick={() => setEditingChannel({ ...channelUI })}>
            Edit
          </Typography.Link>
        ),
      width: '20%',
    },
  ];

  const onUpdateChannelUIs = useCallback(() => {
    const updatedChannels: ChannelData[] = channelUIs.map((data, index) => ({
      channelId: data.id,
      priority: index + 1,
      channelName: data.name,
      disabled: data.disabled,
    }));

    dispatch(updateChannels({ channels: updatedChannels }));
  }, [channelUIs, dispatch]);

  const onResetChannelUIs = useCallback(() => {
    initChannelUIs();
    setPristine(true);
  }, [initChannelUIs]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setChannelUIs((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      setPristine(false);
    }
  };

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex items-center pb-4 justify-between">
        <div className="flex">
          <div className="pr-2">
            <Button type="primary" onClick={onUpdateChannelUIs} disabled={pristine}>
              Update
            </Button>
          </div>
          <div>
            <Button type="primary" onClick={onResetChannelUIs} disabled={pristine}>
              Reset
            </Button>
          </div>
        </div>
        <div className="justify-self-end">
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Create
          </Button>
        </div>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={channelUIs.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={channelUIs}
            pagination={false}
            loading={loading}
          />
        </SortableContext>
      </DndContext>
      <CreateChannelModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};

export default ChannelPriority;
