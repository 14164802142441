import ReportPage from '../pages/Report';

const ReportRoutes = [
  {
    breadcrumb: [{ path: '/report', breadcrumbName: 'User Reported Post' }],
    Component: ReportPage,
  },
];

export default ReportRoutes;
