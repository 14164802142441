import './menu.scss';
import React, { useState } from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Menu as MenuAnt } from 'antd';
import Link from 'gatsby-link';

const { SubMenu } = MenuAnt;
interface MenuProp {
  /**
   * Menu is Open or Closed
   */
  closeMenu: boolean;
  /**
   * Default select item in menu
   */
  defaultSelectedPath?: string;
  /**
   * Item's status
   *
   * itemPath: The page will routing to where when after click the item
   *
   * itemLabel: Item's context
   *
   * itemIcon: Item's icon
   */
  menuItem: {
    itemPath: string;
    itemLabel: string;
    itemIcon: (style: React.CSSProperties) => React.ReactNode;
    subMenuItem?: {
      itemPath: string;
      itemLabel: string;
      key: string;
    }[];
  }[];
  /**
   * Will be triggered when click close button, and pass the next status
   */
  onCloseButtonClick?: (changeStatus: boolean) => void;
}
export const Menu = ({
  closeMenu = false,
  defaultSelectedPath = '',
  menuItem,
  onCloseButtonClick = () => {},
}: MenuProp) => {
  const extendClassName = closeMenu ? 'menu--closed' : 'menu--open';
  const extendButtonClassName = closeMenu ? '' : 'menu--button--open';
  return (
    <MenuAnt
      mode="inline"
      className={`menu--root ${extendClassName}`}
      inlineCollapsed={closeMenu}
      inlineIndent={24}
      defaultSelectedKeys={[defaultSelectedPath]}
    >
      {menuItem.map((item, index) => {
        const { itemIcon, itemLabel, itemPath, subMenuItem } = item;
        return (
          <div key={index.toString()}>
            {(subMenuItem?.length as number) > 0 ? (
              <SubMenu
                title={itemLabel}
                icon={itemIcon({ fontSize: '14px', color: '#000000' })}
                style={{ color: '#000000' }}
                key="sub1"
              >
                {subMenuItem &&
                  subMenuItem.map((subItem) => (
                    <Link
                      key={subItem.itemLabel}
                      to={subItem.itemPath}
                      partiallyActive
                      activeClassName="menu--item--active"
                    >
                      <MenuAnt.Item
                        title={subItem.itemLabel}
                        key={subItem.key}
                        style={{ color: '#000000' }}
                        eventKey={subItem.itemLabel}
                      >
                        {subItem.itemLabel}
                      </MenuAnt.Item>
                    </Link>
                  ))}
              </SubMenu>
            ) : (
              <Link to={itemPath} partiallyActive activeClassName="menu--item--active">
                <MenuAnt.Item
                  icon={itemIcon({ fontSize: '14px', color: '#000000' })}
                  title={itemLabel}
                  style={{ color: '#000000' }}
                  eventKey={itemPath}
                >
                  {itemLabel}
                </MenuAnt.Item>
              </Link>
            )}
          </div>
        );
      })}
      <Button
        type="text"
        onClick={() => {
          onCloseButtonClick(!closeMenu);
        }}
        className={`menu--toggleBtn ${extendButtonClassName}`}
      >
        {React.createElement(closeMenu ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button>
    </MenuAnt>
  );
};

export default Menu;
export type { MenuProp };
