/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { useSortable } from '@dnd-kit/sortable';
import React, { Children, cloneElement, CSSProperties, HTMLAttributes, ReactElement } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

interface DndEditableTableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

interface DragHandlerProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const DragHandler = ({ setActivatorNodeRef, listeners }: DragHandlerProps) => (
  <MenuOutlined
    ref={setActivatorNodeRef}
    style={{ touchAction: 'none', cursor: 'move' }}
    {...listeners}
  />
);

const DndEditableTableRow = ({ children, ...props }: DndEditableTableRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(children, (child) =>
        (child as ReactElement).key === 'dnd'
          ? cloneElement(child as ReactElement, {
              children: (
                <DragHandler setActivatorNodeRef={setActivatorNodeRef} listeners={listeners} />
              ),
            })
          : child,
      )}
    </tr>
  );
};

export default DndEditableTableRow;
