/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './tagLabel.scss';

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag, TagProps } from 'antd';

interface TagLabelProps extends TagProps {
  /**
   * tagLabel type
   */
  labelType?: 'status' | 'level';
  /**
   * status type -- ENABLE, DISABLE, IGNORE (ENABLE=RELEASE, DISABLE=HIDE)
   */
  statusType?: 'ENABLE' | 'DISABLE' | 'IGNORE';
  /**
   * level type
   */
  levelType?: 'normal' | 'vip' | 'goldvip';
  /**
   * Optional Text
   */
  tagText?: string;
  /**
   * show cursor Pointer or not
   */
  cursorPointer?: boolean;
  showDescription?: boolean;
  /**
   * click event
   */
  onClick?: () => void;
}
const TagLabel = ({
  labelType = 'status',
  statusType = 'IGNORE',
  levelType = 'vip',
  tagText,
  cursorPointer = false,
  showDescription = true,
  onClick,
  ...props
}: TagLabelProps) => {
  if (labelType === 'status') {
    let type = '';
    let text = '';
    let icon = <></>;
    if (statusType === 'ENABLE') {
      type = 'success';
      text = 'Release';
      icon = <CheckCircleOutlined />;
    }
    if (statusType === 'DISABLE') {
      type = 'error';
      text = 'Hide';
      icon = <CloseCircleOutlined />;
    }
    if (statusType === 'IGNORE') {
      type = 'default';
      text = 'Ignore';
      icon = <ClockCircleOutlined />;
    }
    return (
      <span className="tagLabel--root">
        <Tag
          className={`${cursorPointer && 'tagLabel--status'} ${
            !showDescription && 'tagLabel--pd-right'
          }`}
          color={type}
          icon={icon}
          onClick={onClick}
          {...props}
        >
          {showDescription && (tagText || text)}
        </Tag>
      </span>
    );
  }
  if (labelType === 'level') {
    let text = '';
    let color = '';
    if (levelType === 'normal') {
      text = 'Normal';
      color = 'default';
    }
    if (levelType === 'vip') {
      text = 'VIP';
      color = 'purple';
    }
    if (levelType === 'goldvip') {
      text = 'Gold-VIP';
      color = 'gold';
    }
    return (
      <div className="tagLabel--root">
        <Tag color={color} {...props} className={`tagLabel--${levelType}`} onClick={onClick}>
          {tagText || text}
        </Tag>
      </div>
    );
  }
  return <></>;
};
export default TagLabel;
