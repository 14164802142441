/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { loginStart } from '@/Slices/AccountSlice';

import { storedCodeDecrypt, storedCodeEncrypt } from '@/Utils/account';

import LogoImg from '@/Images/icon/logo.svg';

interface IsFailedTypes {
  account: boolean;
  password: boolean;
}

interface LoginPayload {
  account: string;
  password: string;
  remember: boolean;
}

const LoginComponent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isLoginError, loginErrorMessage } = useAppSelector((state) => state.account);
  const [isInstalled, setIsInstalled] = useState<boolean>(false);
  const [isRemember, setIsRemember] = useState<boolean>(
    !!(Cookies.get('isRemember') === 'true' && !!Cookies.get('ac') && !!Cookies.get('pd')),
  );

  const [isFailed, setIsFailed] = useState<IsFailedTypes>({
    account: false,
    password: false,
  });

  const [isError, setIsError] = useState<boolean>(false);

  const [forgetPasswordURL, setForgetPasswordURL] = useState<string>('/');

  const handleGetSavedData = (typeName: string) => {
    if (isInstalled) {
      return '';
    }

    const cookiesData: string = Cookies.get(typeName) || '';
    return cookiesData !== '' ? storedCodeDecrypt(cookiesData) : '';
  };

  const handleAccountLogin = (values: LoginPayload) => {
    const { account, password, remember } = values;

    const result = {
      username: account,
      password,
      remember: {
        saved: remember,
        username: storedCodeEncrypt(account),
        password: storedCodeEncrypt(password),
      },
    };

    dispatch(loginStart(result));
  };

  useEffect(() => {
    const ENV: string = process.env.GATSBY_ENV || 'dev';

    const ssoHost: string = (() => {
      switch (ENV) {
        case 'dev':
          return 'sso-dev.hkmpcl.com';
          break;
        case 'staging':
          return 'sso.hkmpcl.com.hk:8443';
          break;
        case 'production':
        case 'prod':
          return 'sso.hktvmall.com';
          break;
        default:
          return 'sso-dev.hkmpcl.com';
          break;
      }
    })();

    setForgetPasswordURL(`https://${ssoHost}/hktv-sso/forget_password`);
    setIsInstalled(true);
  }, []);

  useEffect(() => {
    const { account, password }: IsFailedTypes = isFailed;
    setIsError(account || password);
  }, [isFailed]);

  useEffect(() => {
    if (isLoginError) {
      setIsRemember(false);
      form.setFieldsValue({ password: '', remember: false });
      setIsFailed({ account: true, password: true });
      form.validateFields();
    }
  }, [isLoginError]);

  useEffect(() => {
    if (!isRemember) {
      Cookies.remove('isRemember');
      Cookies.remove('ac');
      Cookies.remove('pd');
    }
  }, [isRemember]);

  const errorMessageMemo = useMemo(
    () => (
      <div className={`text-text-red ${isError ? 'visible' : 'invisible'}`}>
        {loginErrorMessage}
      </div>
    ),
    [isError, loginErrorMessage],
  );

  return (
    <div className="flex justify-center">
      <div className="w-c-360">
        <div className="mb-c-52">
          <div className="flex flex-wrap justify-center">
            <div className="h-c-60 mb-7">
              <img src={LogoImg} alt="KOC logo" className="h-full pointer-events-none" />
            </div>
            <h1 className="font-sans font-medium text-center text-2xl text-text-black2 mb-0">
              FUN 享谷
              <br />
              Content Management System
            </h1>
          </div>
        </div>
        <div className="font-medium mb-c-30 text-primary-primary">Login</div>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            account: isRemember ? handleGetSavedData('ac') : '',
            password: isRemember ? handleGetSavedData('pd') : '',
            remember: true,
          }}
          onChange={() => setIsFailed({ account: false, password: false })}
          onFinishFailed={() => setIsError(true)}
          onFinish={(values) => {
            const { account, password } = values;
            if (!!account && !!password) {
              handleAccountLogin(values);
            }
          }}
        >
          <Form.Item
            name="account"
            rules={[
              { required: true, message: '' },
              () => ({
                validator(_: RuleObject, _value: string) {
                  if (isFailed.account) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon mr-2 text-primary-primary" />}
              placeholder="username: admin or user"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: '' },
              () => ({
                validator(_: RuleObject, _value: string) {
                  if (isFailed.password) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon mr-2 text-primary-primary" />}
              type="password"
              placeholder="password: ant.design"
            />
          </Form.Item>

          {errorMessageMemo}

          <Form.Item className="pt-4 mb-5">
            <div className="flex justify-between">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox checked={isRemember} onChange={(e) => setIsRemember(e.target.checked)}>
                  Remember me
                </Checkbox>
              </Form.Item>

              <Link to={forgetPasswordURL} className="text-primary-primary">
                Forgot your password?
              </Link>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
