import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  PatchPostCategoryParam,
  PinnedLaningRequest,
  PinnedExplorePostRequest,
  PostManagementParam,
  UpdatePostDataParam,
} from '@/Interfaces/I_postManagement';

import {
  getCategories,
  getMallCategories,
  getPinnedLandings,
  getPinnedExplorePosts,
  getPost,
  patchPostCategory,
  patchPostCategorySuccess,
  patchPostData,
  patchPostDataSuccess,
  setCategories,
  setKeywordPost,
  setMallCategories,
  setPinnedLandings,
  updatePinnedLandings,
  setPinnedExplorePosts,
  setPost,
  updatePinnedExplorePosts,
} from '@/Slices/PostManagementSlice';

import {
  fetchCategoriesAPI,
  fetchMallCategoriesAPI,
  fetchPinnedLandingAPI,
  updatePinnedLandingsAPI,
  fetchPinnedExplorePostsAPI,
  fetchPostAPI,
  patchPostCategoryAPI,
  patchPostDataAPI,
  updatePinnedExplorePostsAPI,
} from '@/Axios/api/postManagement';

function* handleGetPost(action: PayloadAction<PostManagementParam>) {
  try {
    const data: AxiosResponse = yield call(fetchPostAPI, action.payload.params);
    yield put(action.payload.pageName === 'keyword' ? setKeywordPost(data) : setPost(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetCategories() {
  try {
    const data: AxiosResponse = yield call(fetchCategoriesAPI);

    yield put(setCategories(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handlePatchPostData(action: PayloadAction<UpdatePostDataParam[]>) {
  try {
    const data: AxiosResponse = yield call(patchPostDataAPI, action.payload);
    yield put(patchPostDataSuccess(data.data));
    message.success({
      content: 'Post(s) updated.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to update Post(s).',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePatchPostCategory(action: PayloadAction<PatchPostCategoryParam>) {
  try {
    yield call(patchPostCategoryAPI, action.payload);
    yield put(patchPostCategorySuccess({}));
    message.success({
      content: 'Post category updated.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to update, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleGetMallCategories() {
  try {
    const data: AxiosResponse = yield call(fetchMallCategoriesAPI);

    yield put(setMallCategories(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetPinnedExplorePosts(action: PayloadAction<string>) {
  try {
    const data: AxiosResponse = yield call(fetchPinnedExplorePostsAPI, action.payload);
    yield put(setPinnedExplorePosts(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetPinnedLanding(action: PayloadAction<string>) {
  try {
    const data: AxiosResponse = yield call(fetchPinnedLandingAPI, action.payload);
    yield put(setPinnedLandings(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleUpdatePinnedLandings(action: PayloadAction<PinnedLaningRequest>) {
  try {
    const data: AxiosResponse = yield call(updatePinnedLandingsAPI, action.payload);
    yield put(setPinnedLandings(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleUpdatePinnedExplorePosts(action: PayloadAction<PinnedExplorePostRequest>) {
  try {
    const data: AxiosResponse = yield call(updatePinnedExplorePostsAPI, action.payload);
    yield put(setPinnedExplorePosts(data));
  } catch (e) {
    // console.log(e);
  }
}

export function* watchGetPost() {
  yield takeLatest(getPost.type, handleGetPost);
}

export function* watchGetCategories() {
  yield takeLatest(getCategories.type, handleGetCategories);
}

export function* watchPatchExtraScore() {
  yield takeEvery(patchPostData.type, handlePatchPostData);
}

export function* watchPatchPostCategory() {
  yield takeLatest(patchPostCategory.type, handlePatchPostCategory);
}

export function* watchGetMallCategories() {
  yield takeLatest(getMallCategories.type, handleGetMallCategories);
}

export function* watchGetPinnedLanding() {
  yield takeLatest(getPinnedLandings.type, handleGetPinnedLanding);
}

export function* watchUpdatePinnedLandings() {
  yield takeLatest(updatePinnedLandings.type, handleUpdatePinnedLandings);
}

export function* watchGetPinnedExplorePosts() {
  yield takeLatest(getPinnedExplorePosts.type, handleGetPinnedExplorePosts);
}

export function* watchUpdatePinnedExplorePosts() {
  yield takeLatest(updatePinnedExplorePosts.type, handleUpdatePinnedExplorePosts);
}
