import { createSlice } from '@reduxjs/toolkit';

import { AccountsManagementData } from '@/Interfaces/I_AccountsManagement';
import { PaginationData } from '@/Interfaces/I_postManagement';

export const accountsManagementSlice = createSlice({
  name: 'accountsManagementSlice',
  initialState: {
    value: {
      accountsManagement: {
        data: [] as AccountsManagementData[],
        pagination: {} as PaginationData,
      },
    },
    loading: false,
    error: '',
  },
  reducers: {
    getAccountsManagements: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setAccountsManagements: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, accountsManagement: action.payload.data };
      assignState.loading = false;
    },
    patchAccountsDetails: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    patchAccountsDetailsSuccess: (state, action) => {
      const { userUuid, disabled } = action.payload;
      const updateData = [...state.value.accountsManagement.data];
      const accountsManagementIndex = updateData.findIndex((e) => e.uuid === userUuid);
      const assignState = state;
      assignState.value.accountsManagement.data[accountsManagementIndex] = {
        ...assignState.value.accountsManagement.data[accountsManagementIndex],
        disabled,
      };
      assignState.loading = false;
    },
  },
});

export const {
  getAccountsManagements,
  setAccountsManagements,
  patchAccountsDetails,
  patchAccountsDetailsSuccess,
} = accountsManagementSlice.actions;

export default accountsManagementSlice.reducer;
