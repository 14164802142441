import WhitelistDomains from '@/Pages/WhitelistDomains';

const WhitelistDomainsRoutes = [
  {
    breadcrumb: [{ path: '/whitelist-domains', breadcrumbName: 'Whitelist Domains' }],
    Component: WhitelistDomains,
  },
];

export default WhitelistDomainsRoutes;
