import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loading = ({ hasBackground = true }: { hasBackground?: boolean }) => (
  <div
    className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center pointer-events-none
    ${hasBackground && 'bg-bg'}`}
  >
    <Spin indicator={<LoadingOutlined className="text-c-40" spin />} />
  </div>
);

export default Loading;
