import { CollectionPostsRequest, PostCollection } from '@/Interfaces/I_PostCollection';
import { customAxios } from '..';

export const fetchPostCollections = () => customAxios.get('hktv_koc/cms/collections');

export const createPostCollection = (data: PostCollection) =>
  customAxios.post('hktv_koc/cms/collections/create', data);

export const updatePostCollection = (data: PostCollection[]) =>
  customAxios.post('hktv_koc/cms/collections/update', data);

export const getCollectionPostsByUuid = (uuid: string) =>
  customAxios.get(`hktv_koc/cms/collections/${uuid}`);

export const createCollectionPosts = (data: CollectionPostsRequest) =>
  customAxios.post('hktv_koc/cms/collections/posts/create', data);

export const deleteCollectionPost = (data: CollectionPostsRequest) =>
  customAxios.delete('hktv_koc/cms/collections/posts/delete', { data });

export const updateCollectionPosts = (data: CollectionPostsRequest) =>
  customAxios.post('hktv_koc/cms/collections/posts/update', data);
