/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Input, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getPostDetail, getReasons, getReportRecord, postReport } from '@/Redux/slices/ModalSlice';
import { getReportPost } from '@/Redux/slices/ReportSlice';

import Modal, { ModalProps } from '@/Modules/modal';
import ReasonCard from '@/Modules/reasonCard';
import TagLabel from '@/Modules/tagLabel';

import { timeTranslate } from '@/Utils/timeTranslate';
import { getFrontEndDomainByEnv } from '@/Axios/index';
import fileText from '../../images/icon/fileText.svg';

const { Search } = Input;

const Report = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const hasForm = useRef(false);
  const formOk = useRef(false);

  const userName = useAppSelector((state) => state.account.user.username);
  const postArray = useAppSelector((state) => state.report.value.hidLog.data);
  const postPagination = useAppSelector((state) => state.report.value.hidLog.pagination);
  const postLoading = useAppSelector((state) => state.report.loading);
  const reasonArray = useAppSelector((state) => state.modal.value.reasons);
  const postReportMessage = useAppSelector((state) => state.modal.message);

  const [keyword, setKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSort, setCurrentSort] = useState<null | string>(null);
  const [postUuid, setPostUid] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({});
  const [hasValue, setHasValue] = useState(false);
  const [handleRecord, setHandleRecord] = useState<
    {
      key: string | number;
      handlingTime: string;
      admin: string;
      action: string;
      reason: string;
    }[]
  >([]);

  const columns = [
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: '9.3%',
      render: (text: string) => <p className="h-6 line-clamp-1 m-0">{text}</p>,
    },
    {
      title: 'Post Title',
      dataIndex: 'postTitle',
      key: 'postTitle',
      render: (postTitle: { title: string; postId: string }) => (
        <div className="flex">
          <a 
          href= {`${getFrontEndDomainByEnv()}/post/${postTitle.postId}`}
          target="_blank"
          rel="noreferrer" 
          className="h-6 line-clamp-1 break-all m-0">{postTitle.title}</a>
          <img
            aria-hidden
            src={fileText}
            alt=""
            className="cursor-pointer ml-2"
            onClick={() => {
              dispatch(getPostDetail(postTitle.postId));
              setModalProps({
                modalType: 'postDetail',
              });
              setIsModalVisible(true);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Last Handling Time',
      dataIndex: 'lastHandlingTime',
      key: 'lastHandlingTime',
      width: 174,
      sorter: true,
      sortOrder: currentSort as SortOrder,
    },
    {
      title: 'Sex, violence',
      key: 'sexViolence',
      dataIndex: 'sexViolence',
      width: 85,
    },
    {
      title: 'Hate speech',
      key: 'hateSpeech',
      dataIndex: 'hateSpeech',
      width: 78,
    },
    {
      title: 'Untrue info.',
      key: 'untrueInfo',
      dataIndex: 'untrueInfo',
      width: 74,
    },
    {
      title: 'Harass, bullying',
      key: 'harassBullying',
      dataIndex: 'harassBullying',
      width: 81,
    },
    {
      title: 'Infringing',
      key: 'infringing',
      dataIndex: 'infringing',
      width: 93,
    },
    {
      title: 'Plagiarism',
      key: 'plagiarism',
      dataIndex: 'plagiarism',
      width: 100,
    },
    {
      title: 'Ad',
      key: 'ad',
      dataIndex: 'ad',
      width: 56,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 148,
      className: 'pr-0',
      render: (postId: string) => (
        <div className="flex items-center">
          <TagLabel
            labelType="status"
            statusType="ENABLE"
            cursorPointer
            onClick={() => tagClickEvent('ENABLE', postId)}
          />
          <Button
            className="p-0"
            type="link"
            onClick={() => {
              dispatch(getReportRecord({ post_uuid: postId }));
              setModalProps({
                modalType: 'reportRecord',
                data: {
                  postUuid: postId,
                  onDetailClick: (reportId: number | string) => {
                    setIsPending(reportId === undefined);
                    setIsSecondModalVisible(true);
                  },
                  onRowClick: (record: {
                    key: string | number;
                    handlingTime: string;
                    admin: string;
                    action: string;
                    reason: {
                      text: string;
                    };
                  }) => {
                    setHandleRecord([
                      {
                        key: record.key,
                        handlingTime: record.handlingTime,
                        admin: record.admin,
                        action: record.action,
                        reason: record.reason.text,
                      },
                    ]);
                  },
                },
              });
              setIsModalVisible(true);
            }}
          >
            Record
          </Button>
        </div>
      ),
    },
  ];

  const tagClickEvent = useCallback((type: string, postId: string) => {
    setModalProps({});
    dispatch(getReasons({ reason_type: type }));
    hasForm.current = true;
    setPostUid(postId);
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    if (userName) {
      dispatch(
        getReportPost({
          pageName: 'hidLog',
          params: { handle_type: 'DISABLE', sort: 'DATE_ASC', post_title: keyword },
        }),
      );
    }
  }, [dispatch, userName, keyword]);

  useEffect(() => {
    if (postReportMessage === 'success' && formOk.current) {
      formOk.current = false;
      dispatch(
        getReportPost({
          pageName: 'hidLog',
          params: {
            handle_type: 'DISABLE',
            page_no: currentPage,
            sort: currentSort === 'descend' ? 'DATE_DESC' : 'DATE_ASC',
            post_title: keyword,
          },
        }),
      );
    }
  }, [postReportMessage]);

  useEffect(() => {
    if (isModalVisible && hasForm.current) {
      setHasValue(false);
      hasForm.current = false;
    }
  }, [form, isModalVisible]);

  useEffect(() => {
    if (reasonArray.length > 0) {
      let title = '';
      const { reasonType } = reasonArray[0];
      switch (reasonType) {
        case 'DISABLE':
          title = 'Hide Reasons';
          break;
        case 'IGNORE':
          title = 'Ignore Reasons';
          break;
        case 'ENABLE':
          title = 'Release Reasons';
          break;
        default:
      }
      setModalProps({
        content: <ReasonCard form={form} setHasValue={setHasValue} checkBoxArray={reasonArray} />,
        title,
        onCancel: () => {
          form.resetFields();
          setIsModalVisible(false);
        },
        onOk: () => {
          form
            .validateFields()
            .then((values) => {
              const reasonIds = values.reason.filter((value: string | number) => value !== 'other');
              const otherReason = values.input;
              dispatch(
                postReport({
                  actionType: reasonType,
                  otherReason: otherReason ? otherReason.trim() : '',
                  postUuid,
                  reasonIds,
                }),
              );
              formOk.current = true;
              form.resetFields();
              setIsModalVisible(false);
            })
            .catch(() => {});
        },
      });
    }
  }, [reasonArray]);

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <Search
        placeholder="input search text"
        allowClear
        className="w-c-264"
        onSearch={(e) => setKeyword(e)}
      />

      <Table
        className="mt-4"
        columns={columns}
        loading={postLoading}
        dataSource={postArray?.map((data) => ({
          key: data.postUuid,
          categories: data.categories ? data.categories.join(',') : 'categories',
          postTitle: {
            title: data.postTitle || 'title',
            postId: data.postUuid,
          },
          lastHandlingTime: data.lastHandledDate ? timeTranslate(data.lastHandledDate, true) : '',
          sexViolence: data.reasonCount[1] || 0,
          hateSpeech: data.reasonCount[2] || 0,
          untrueInfo: data.reasonCount[3] || 0,
          harassBullying: data.reasonCount[4] || 0,
          infringing: data.reasonCount[5] || 0,
          plagiarism: data.reasonCount[6] || 0,
          ad: data.reasonCount[7] || 0,
          action: data.postUuid,
        }))}
        pagination={{
          total: postPagination ? postPagination.total : 0,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 10,
          defaultCurrent: 1,
          showSizeChanger: true,
          onChange: (page: number) => {
            setCurrentPage(page - 1);
            dispatch(
              getReportPost({
                pageName: 'hidLog',
                params: {
                  handle_type: 'DISABLE',
                  sort: currentSort === 'descend' ? 'DATE_DESC' : 'DATE_ASC',
                  page_no: page - 1,
                  post_title: keyword,
                },
              }),
            );
          },
        }}
        onChange={(_pagination, _filters, sorter, extra) => {
          const { order } = sorter as SorterResult<object>;
          if (extra.action === 'sort') {
            setCurrentSort(order as string | null);
            dispatch(
              getReportPost({
                pageName: 'hidLog',
                params: {
                  handle_type: 'DISABLE',
                  sort: order === 'descend' ? 'DATE_DESC' : 'DATE_ASC',
                  page_no: currentPage,
                  post_title: keyword,
                },
              }),
            );
          }
        }}
      />
      <Modal
        {...modalProps}
        okButtonProps={{
          disabled: !hasValue,
        }}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      />
      {/* 第二層modal */}
      <Modal
        visible={isSecondModalVisible}
        onCancel={() => setIsSecondModalVisible(false)}
        data={{ userRecordData: handleRecord, pending: isPending }}
        modalType="userRecord"
      />
    </div>
  );
};

export default Report;
