import React, { useState } from 'react';

import Tab from '@/Modules/tab/Tab';

import Category from './Category';
import KeywordSearch from './KeywordSearch';
import PinnedLanding from './PinnedLanding';
import ExplorePage from './ExplorePage';
import PrpPost from './PrpPost';

const Index = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <Tab
      defaultActiveKey={activeTab}
      initialPanes={[
        {
          title: 'Categories',
          content: <Category tabActive={activeTab === '1'} />,
          key: '1',
        },
        { title: 'Keyword search', content: <KeywordSearch />, key: '2' },
        {
          title: 'Landing Post',
          content: <PinnedLanding tabActive={activeTab === '3'} />,
          key: '3',
        },
        { title: 'Explore Page', content: <ExplorePage tabActive={activeTab === '4'} />, key: '4' },
        { title: 'PRP Post', content: <PrpPost tabActive={activeTab === '5'} />, key: '5' },
      ]}
      onChange={(activeKey: string) => setActiveTab(activeKey)}
    />
  );
};

export default Index;
