/* eslint-disable react/jsx-props-no-spreading */
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { CollectionPostsRequest } from '@/Interfaces/I_PostCollection';
import { createCollectionPosts } from '@/Redux/slices/PostCollectionSlice';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormListFieldData, Input, Modal, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';

interface AddCollectionPostModalProps {
  isModalVisible: boolean;
  setModalVisible: (value: boolean) => void;
}

const PostUuidInput = ({
  last,
  field,
  add,
  remove,
}: {
  last: boolean;
  field: FormListFieldData;
  add: (value: string) => void;
  remove: (index: number) => void;
}) => {
  const icon = last ? (
    <PlusCircleOutlined
      style={{ top: '0' }}
      className="dynamic-delete-button"
      onClick={() => add('')}
    />
  ) : (
    <MinusCircleOutlined
      style={{ top: '0' }}
      className="dynamic-delete-button"
      onClick={() => remove(field.name)}
    />
  );

  return (
    <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
      <Form.Item noStyle {...field}>
        <Input style={{ flexGrow: '1' }} />
      </Form.Item>
      {icon}
    </div>
  );
};

const AddCollectionPostModal = ({
  isModalVisible,
  setModalVisible,
}: AddCollectionPostModalProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { uuid } = useAppSelector((state) => state.postCollection.value.currentPostCollection);

  const onFinish = () => {
    const uuids = form.getFieldValue('uuids');
    const data: CollectionPostsRequest = {
      collectionUuid: uuid,
      postUuids: uuids,
    };

    dispatch(createCollectionPosts(data));
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Input Post UUID:"
      open={isModalVisible}
      footer={null}
      onCancel={() => setModalVisible(false)}
    >
      <Form
        name="dynamic_form_item"
        id="addCollectionPostsForm"
        onFinish={onFinish}
        form={form}
        initialValues={{ uuids: [''] }}
        preserve={false}
      >
        <Form.List name="uuids">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <PostUuidInput
                    last={index === fields.length - 1}
                    field={field}
                    add={add}
                    remove={remove}
                  />
                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item noStyle>
          <div className="grid justify-items-end">
            <Button id="addCollectionPostsForm" htmlType="submit">
              Add
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCollectionPostModal;
