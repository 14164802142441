import Censorship from '../pages/Censorship';

const CensorshipRoutes = [
  {
    breadcrumb: [{ path: '/censorship', breadcrumbName: 'Censorship' }],
    Component: Censorship,
  },
];

export default CensorshipRoutes;
