/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useMemo, useRef } from 'react';
import 'video.js/dist/video-js.css';
import videojs, { VideoJsPlayer } from 'video.js';
import { VIDEO_PLAYER } from '@/Interfaces/I_vod';

// ------------ PostVideoPlayer copied from koc frontend ------------

type InViewAutoPlayVideoProps = React.VideoHTMLAttributes<HTMLVideoElement>;

type VideoDefaultOptions = Omit<videojs.PlayerOptions, 'sources' | 'poster'>;

const PostVideoPlayer = ({ src, poster }: InViewAutoPlayVideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const defaultOptions = useMemo<VideoDefaultOptions>(
    () =>
      ({
        controls: true,
        controlBar: {
          fullscreenToggle: true,
          pictureInPictureToggle: false,
          subtitlesButton: false,
        },
        fluid: true,
        muted: false,
        loop: false,
        preload: 'auto',
        autoplay: false,
      } as VideoDefaultOptions),
    [],
  );

  useEffect(() => {
    if (!src || !poster) return;
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, {
        ...defaultOptions,
        sources: [
          {
            src,
            type: 'application/x-mpegURL',
          },
        ],
        // poster,
      });
    } else {
      const player = playerRef.current;
      player.poster(poster);
      player.src(src);
    }
  }, [defaultOptions, poster, src]);

  return (
    <div data-vjs-player>
      <video
        className="video-js vjs-big-play-centered"
        playsInline
        ref={videoRef}
        id={VIDEO_PLAYER}
      />
    </div>
  );
};

export default PostVideoPlayer;
