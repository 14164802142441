/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';

import { putMallCategoryParams } from '@/Interfaces/I_setting';

import { customAxios } from '../index';

interface Response<T> {
  data: T;
  status: {
    code: string;
    message: string;
  };
}

const getSettingApi = (): Promise<AxiosResponse<Response<unknown>>> =>
  customAxios.get('/hktv_koc/cms/setting');

const putMallCategoryApi = (data: putMallCategoryParams) => {
  const { mallCategoryCode, ...formData } = data;
  return customAxios({
    url: `hktv_koc/cms/setting/mall_category/${mallCategoryCode}`,
    method: 'PUT',
    data: formData,
  });
};

export { getSettingApi, putMallCategoryApi };
