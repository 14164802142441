import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { call, put, takeEvery } from 'redux-saga/effects';
import { refreshTnc, getTnc, setTnc, updateTnc } from '@/Redux/slices/RebateTncSlice'
import { getTncAPI, updateTncAPI } from '@/Axios/api/TermsAndConditions';
import { PutTermsAndConditionsPayload } from '@/Interfaces/I_TermsAndConditions';


function* handleGetTnc(action: PayloadAction<string>) {
  try {
    const data: AxiosResponse = yield call(getTncAPI,{langauge: action.payload, usage: 'rebate'});
    yield put(setTnc(data.data));
  } catch (e) {
    console.log(e);
    message.error({
      content: 'Fail to get Rebate Terms and Conditions.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePutTnc(action: PayloadAction<PutTermsAndConditionsPayload>) {
  try {
    const data: AxiosResponse = yield call(updateTncAPI, action.payload);
    yield put(refreshTnc(data));
  } catch (e) {
    message.error({
      content: 'Fail to update Rebate Terms and Conditions.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetTnc() {
  yield takeEvery(getTnc.type, handleGetTnc);
}
export function* watchPutTnc() {
  yield takeEvery(updateTnc.type, handlePutTnc);
}