import React from 'react';

import Oops from '@/Images/404.png';

const NotFoundPage: React.FC = () => (
  <div className="h-screen w-full flex justify-center items-center bg-bg">
    <div className="h-c-244 w-c-300">
      <img src={Oops} alt="Oops" className="object-cover" />
    </div>
  </div>
);

export default NotFoundPage;
