import { CampaignParam, CampaignUiData } from '@/Interfaces/I_Campaign';

import { customAxios } from '../index';

const getCampaignDataApi = (data: CampaignParam) =>
  customAxios({
    url: 'hktv_koc/cms/campaigns',
    method: 'GET',
    params: data,
  });
const postCampaignDataApi = (data: CampaignUiData) =>
  customAxios({
    url: 'hktv_koc/cms/campaigns',
    method: 'POST',
    data,
  });
const patchCampaignDataApi = (data: CampaignUiData) =>
  customAxios({
    url: 'hktv_koc/cms/campaigns',
    method: 'PATCH',
    data,
  });

export { getCampaignDataApi, postCampaignDataApi, patchCampaignDataApi };
