import LiveShow from '@/Pages/LiveShow';

const LiveShowRoutes = [
  {
    breadcrumb: [{ path: '/live-show', breadcrumbName: 'Live Show' }],
    Component: LiveShow,
  },
];

export default LiveShowRoutes;
