/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { Card, Alert, Button, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getTnc, setTnc, updateTnc } from '@/Redux/slices/RebateTncSlice';


const RebateTnc = () => {
  const dispatch = useAppDispatch();
  const { tnc, updateCode } = useAppSelector((state) => state.rebateTnc.value);
  const [ localTnc, setLocalTnc ] = useState<string>("");
  const [alertType, setAlertType] = useState<'success' | 'error' | undefined>(undefined);
 
 
  useEffect(() => {
    dispatch(getTnc('zh'));
  }, [dispatch]);

  useEffect(() => {
    setLocalTnc(tnc);
  },[tnc])

  useEffect(() => {
    if (updateCode) {
      if (updateCode === 200) {
        setAlertType('success');
      }
      if (updateCode !== 200 && updateCode !== 0) {
        setAlertType('error');
      }
    }
  }, [updateCode]);

  return (
    <>
      {!!alertType && (
        <Alert
          message={
            alertType === 'success' ? 'Saved successfully.' : 'Saved failed. Please try again.'
          }
          type={alertType}
          className="mb-6"
          showIcon
        />
      )}
      <div className = "flex justify-end">
        <Button
          type="primary"
          disabled={!localTnc}
          onClick={() => {
            dispatch(updateTnc({language: "zh", usage: "rebate", content: localTnc}));
          }}
        >
          Save
        </Button>
      </div>
       
      <div className="bg-white my-6 py-4 px-6">
        {/* <Card title="Rebate Terms of Service" size="small" /> */}
        <TextArea
          value={localTnc}
          onChange={(e) => {
            setLocalTnc(e.target.value);
          }}
          placeholder="Please write Terms and Condition Here."
          style={{ height: '500px', padding: '16px 24px' }}
        />
      </div>
    </>
  );
};

export default RebateTnc;
