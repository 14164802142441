/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';
import {
  Empty,
  Form,
  Popconfirm,
  Switch,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useForm } from 'antd/lib/form/Form';
import { DndFormInstance } from '@/Hooks/useDndForm';
import { isArray } from '@apollo/client/utilities';
import DndEditableTableRow from './DndEditableTableRow';
import DndEditableTableCell, { DndEditableTableCellProps } from './DndEditableTableCell';

export type DndEditableTableData = Required<{ key: string }>;

export type DndEditableTableColumnProps<T extends DndEditableTableData> =
  TableColumnsType<T>[number] & {
    dataIndex: string | string[];
    editable?: boolean;
    inputType?: DndEditableTableCellProps['inputType'];
    uploadImageProps?: DndEditableTableCellProps['uploadImageProps'];
  };

interface DndEditableTableProps<T extends DndEditableTableData> {
  records: T[];
  columns: DndEditableTableColumnProps<T>[];
  editable?: boolean;
  antdTableProps?: Omit<
    TableProps<T>,
    'columns' | 'rowKey' | 'dataSource' | 'components' | 'pagination'
  >;
  dndForm?: DndFormInstance<T>;
}

interface OperationColumnProps<T extends DndEditableTableData> {
  editable: boolean;
  editing: boolean;
  record: T;
  index: number;
  onEdit: (record: T, index: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

const OperationColumn = <T extends DndEditableTableData>({
  editable,
  editing,
  record,
  index,
  onEdit,
  onSave,
  onCancel,
}: OperationColumnProps<T>) => {
  if (!editing) {
    return (
      <Typography.Link disabled={!editable} onClick={() => onEdit(record, index)}>
        Edit
      </Typography.Link>
    );
  }

  return (
    <span>
      <Typography.Link onClick={() => onSave()} style={{ marginInlineEnd: 8 }}>
        Save
      </Typography.Link>
      <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
        <Typography.Link>Cancel</Typography.Link>
      </Popconfirm>
    </span>
  );
};

const DndEditableTable = <T extends DndEditableTableData>({
  records,
  columns,
  editable = true,
  antdTableProps,
  dndForm,
}: DndEditableTableProps<T>) => {
  const [form] = useForm(dndForm?.form);
  const [dataSource, setDataSource] = useState(records);
  const [editingRecordKey, setEditingRecordKey] = useState('');

  useEffect(() => {
    dndForm?.setIsPristine(true);
    setDataSource(records);
  }, [records]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((record) => record.key === active?.id);
      const overIndex = dataSource.findIndex((record) => record.key === over?.id);
      const updatedDataSource = arrayMove(dataSource, activeIndex, overIndex);

      setDataSource(updatedDataSource);
      dndForm?.updateCurrentValue(updatedDataSource);
    }
  };

  const onSave = async () => {
    try {
      await form.validateFields();
      const updatedData = form.getFieldsValue(true);

      const newData = [...dataSource];
      const index = newData.findIndex((item) => editingRecordKey === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...updatedData,
        });
        setDataSource(newData);
        setEditingRecordKey('');

        dndForm?.updateCurrentValue(newData);
      }
    } catch (err) {
      /* empty */
    }
  };

  const getRecordValue = (record: any, dataIndex: string | string[]) =>
    isArray(dataIndex)
      ? dataIndex.reduce((obj, key) => obj && obj[key], record)
      : record[dataIndex];

  const dndColumns = [
    {
      key: 'dnd',
      width: 80,
      align: 'center' as const,
      dataIndex: 'dnd',
    },
    ...columns.map((column) => ({
      ...column,
      onCell: (record: T): DndEditableTableCellProps => ({
        dataIndex: column.dataIndex,
        inputType: column.inputType || 'text',
        editable: column.editable ?? true,
        editing: editingRecordKey === record.key,
        value: getRecordValue(record, column.dataIndex),
        uploadImageProps: column.uploadImageProps,
      }),
      render: (value: any, record: T, index: number) => {
        if (column.render) {
          return column.render(value, record, index);
        }

        return column.inputType === 'boolean' ? <Switch disabled checked={value} /> : value;
      },
    })),
    editable
      ? {
          key: 'operation',
          dataIndex: 'operation',
          title: 'Operation',
          render: (_: unknown, record: T, index: number) => (
            <OperationColumn
              editable={editable}
              editing={record.key === editingRecordKey}
              record={record}
              index={index}
              onSave={onSave}
              onEdit={() => {
                setEditingRecordKey(record.key);
                form.setFieldsValue({ ...record });
              }}
              onCancel={() => setEditingRecordKey('')}
            />
          ),
        }
      : {},
  ];

  return records.length > 0 ? (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource.map((d) => d.key)}>
        <Form form={form} component={false}>
          <Table
            rowKey="key"
            dataSource={dataSource}
            columns={dndColumns}
            components={{ body: { row: DndEditableTableRow, cell: DndEditableTableCell } }}
            pagination={false}
            {...antdTableProps}
          />
        </Form>
      </SortableContext>
    </DndContext>
  ) : (
    <Empty />
  );
};

export default DndEditableTable;
