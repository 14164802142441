import React, { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import { Button, Empty, Input, Popconfirm, Select } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { AccountsManagementData } from '@/Interfaces/I_AccountsManagement';

import {
  getAccountsManagements,
  patchAccountsDetails,
} from '@/Redux/slices/AccountsManagementSlice';

import Table from '@/Modules/table/Table';
import TagLabel from '@/Modules/tagLabel';
import UserRoleLabel from '@/Modules/userRoleLabel/UserRoleLabel';

import { timeTranslate } from '@/Utils/timeTranslate';
import { UserRoleEnums } from '@/Interfaces/I_userRole';

const { Search } = Input;

interface UserAccountUIData {
  name: string;
  membershipLevel: string;
  hybrisId: string;
  creationDate: number;
  postNo: number;
  rebatedSum: number;
  blockedPostNo: number;
  reportedPostNo: number;
  action: { disabled: boolean; userUuid: string };
}

interface SorterDataType {
  name: string;
  pKey: number;
  creationDate: number;
}

interface ExportCsvData {
  User: string;
  Membership: string;
  UserPkey: string;
  AccountCreated: string;
  PostCount: number;
  RebateAmount: number;
  BlockedPostCount: number;
  ReportedPostCount: number;
  Action: boolean;
}

const UserAccount = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.accountsManagement.loading);
  const accountsManagementData = useAppSelector(
    (state) => state.accountsManagement.value.accountsManagement,
  );

  const [userAccountData, setUserAccountData] = useState([] as UserAccountUIData[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [searchOption, setSearchOption] = useState('hybrisId');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectRowKeys, setSelectRowKeys] = useState([] as Array<React.Key>);
  const [selectRow, setSelectRow] = useState([] as ExportCsvData[]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterDataType>>({
    order: 'descend',
    columnKey: 'creationDate',
  });

  const tagClickEvent = useCallback(
    (type: string, userUuid: string) => {
      dispatch(
        patchAccountsDetails({
          userUuid,
          disabled: type === 'DISABLE',
        }),
      );
    },
    [dispatch],
  );

  const searchOptions = [
    {
      value: 'hybrisId',
      label: 'Pkey',
    },
    {
      value: 'name',
      label: 'User Name',
    },
  ];

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      render: (text: string) => <p className="h-6 line-clamp-1 break-all m-0">{text}</p>,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
    },
    {
      title: 'Membership',
      dataIndex: 'membershipLevel',
      key: 'membershipLevel',
      width: 107,
      className: 'pr-0',
      render: (text: UserRoleEnums) =>
        text !== 'CUSTOMER' ? (
          <UserRoleLabel userRoleStr={text} />
        ) : (
          <TagLabel
            labelType="level"
            levelType={text.toLowerCase() as 'normal' | 'vip' | 'goldvip'}
          />
        ),
    },
    {
      title: 'PKey',
      dataIndex: 'hybrisId',
      key: 'hybrisId',
      width: 103,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'hybrisId' ? sortedInfo.order : null,
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: 133,
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'creationDate' ? sortedInfo.order : null,
    },
    {
      title: 'Post',
      dataIndex: 'postNo',
      key: 'postNo',
      width: 103,
    },
    {
      title: 'Rebate ($)',
      dataIndex: 'rebatedSum',
      key: 'rebatedSum',
      width: 103,
    },
    {
      title: 'Blocked Post',
      dataIndex: 'blockedPostNo',
      key: 'blockedPostNo',
      width: 103,
    },
    {
      title: 'Reported Post',
      dataIndex: 'reportedPostNo',
      key: 'reportedPostNo',
      width: 103,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 146,
      className: 'pr-0',
      render: (item: { disabled: boolean; userUuid: string }) => {
        const { disabled, userUuid } = item;
        const type = disabled === true ? 'ENABLE' : 'DISABLE';
        return (
          <div className="flex justify-between items-center">
            <div style={{ width: 77.5 }} className="flex justify-center">
              <Popconfirm
                title="Confirm to delete?"
                onConfirm={() => tagClickEvent(type, userUuid)}
                okText="Yes"
                cancelText="No"
              >
                <TagLabel labelType="status" statusType={type} cursorPointer />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const sort =
      sortedInfo.column !== undefined
        ? (sortedInfo.field as string)
            .replace(/[A-Z]/g, (letter) => `_${letter}`)
            .toUpperCase()
            .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
        : 'CREATION_DATE_DESC';
    dispatch(
      getAccountsManagements({
        hybris_id: searchOption === 'hybrisId' ? searchValue.trim() : null,
        name: searchOption === 'name' ? searchValue.trim() : null,
        page_no: currentPage - 1,
        page_size: pageSize,
        sort,
      }),
    );
    setSelectRowKeys([]);
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchOption,
    searchValue,
    sortedInfo.field,
    sortedInfo.order,
    sortedInfo.column,
  ]);

  useEffect(() => {
    if (!loading && accountsManagementData.data) {
      const disaplyTableData: UserAccountUIData[] = accountsManagementData.data.map(
        (item: AccountsManagementData) => ({
          key: item.uuid,
          name: item.name,
          membershipLevel:
            item.userRole && item.userRole !== 'CUSTOMER' ? item.userRole : item.membershipLevel,
          hybrisId: item.hybrisId,
          creationDate: item.creationDate,
          postNo: item.postCount,
          rebatedSum: item.rebatedSum,
          blockedPostNo: item.hasBlockedPostCount,
          reportedPostNo: item.hasReportedPostCount,
          action: { disabled: item.disabled, userUuid: item.uuid },
        }),
      );
      setUserAccountData(disaplyTableData);
      setTotalPage(accountsManagementData.pagination.total);
    }
  }, [
    loading,
    accountsManagementData.data,
    accountsManagementData.pagination.page,
    accountsManagementData.pagination.total,
    pageSize,
  ]);

  const exportCsv = useCallback((selectedRows: UserAccountUIData[]) => {
    const filterData = selectedRows
      .filter((item: UserAccountUIData) => item !== undefined)
      .map((item: UserAccountUIData) => ({
        User: item.name,
        Membership: item.membershipLevel,
        UserPkey: item.hybrisId,
        AccountCreated: `${timeTranslate(item.creationDate)}`,
        PostCount: item.postNo,
        RebateAmount: item.rebatedSum,
        BlockedPostCount: item.blockedPostNo,
        ReportedPostCount: item.reportedPostNo,
        Action: item.action.disabled,
      }));
    setSelectRow(filterData);
  }, []);

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex pb-4 justify-between">
        <div className="flex ml-auto items-center">
          <div className="pr-4">
            <Select
              style={{ width: 200 }}
              defaultValue={searchOption}
              options={searchOptions}
              onChange={(value) => {
                setSearchOption(value);
              }}
              bordered={false}
            />
          </div>
          <Search
            placeholder="input text"
            allowClear
            onSearch={(value) => {
              setSearchValue(value.trim());
              setCurrentPage(1);
            }}
            style={{ width: 264 }}
            className="pr-4"
          />
          <Button disabled={selectRowKeys.length === 0}>
            <CSVLink uFEFF filename="download.csv" data={selectRow} target="_blank">
              Generate CSV
            </CSVLink>
          </Button>
        </div>
      </div>
      {!loading && userAccountData.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      ) : (
        <Table
          columns={columns}
          dataSource={userAccountData}
          loading={loading}
          pagination={{
            total: totalPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultPageSize: pageSize,
            defaultCurrent: 1,
            showSizeChanger: true,
            current: currentPage,
            onChange: (page: number, selectPageSize: number) => {
              setCurrentPage(page);
              setTotalPage(selectPageSize);
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
          rowSelection={{
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectRowKeys,
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectRowKeys(selectedRowKeys);
              exportCsv(selectedRows as UserAccountUIData[]);
            },
          }}
          onChange={(pagination, filters, sorter) => {
            const sorterInfo = sorter as SorterResult<SorterDataType>;
            setSortedInfo(sorterInfo);
          }}
        />
      )}
    </div>
  );
};

export default UserAccount;
