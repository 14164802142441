/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Collapse, Upload, Modal, UploadProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { deleteImage, getLiveShowInfo, updateLiveShowInfo } from '@/Redux/slices/LiveShowSlice';

import DeleteIconButton from '@/Modules/deleteIconButton/DeleteIconButton';
import type { UploadFile } from 'antd/es/upload/interface';
import { customAxios } from '@/Axios/index';
import { uploadKocPhotoUrl } from '@/Axios/api/Media';

const { Panel } = Collapse;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 8 },
  },
};

const LiveShow = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { bannerImageUrl, promotionCategory, pinnedLiveShowMediaUrls, promotionLiveShowMediaUrls } =
    useAppSelector((state) => state.liveShow);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const imageId = Form.useWatch('bannerImageId', form);

  const bearerToken = customAxios.defaults.headers.common.Authorization;
  const uploadPhotoUrl = customAxios.defaults.baseURL + uploadKocPhotoUrl;

  const onFinish = (values: any) => {
    dispatch(updateLiveShowInfo(values));
  };

  useEffect(() => {
    dispatch(getLiveShowInfo());
  }, [dispatch]);
  useEffect(() => {
    form.setFieldsValue({
      bannerImageUrl,
      promotionCategory,
      pinnedLiveShowMediaUrls,
      promotionLiveShowMediaUrls,
      bannerImageId: null,
    });
    setFileList([
      {
        uid: 'originalBannerImage',
        name: 'originalBannerImage',
        status: 'done',
        url: bannerImageUrl,
      },
    ]);
  }, [
    form,
    bannerImageUrl,
    pinnedLiveShowMediaUrls,
    promotionCategory,
    promotionLiveShowMediaUrls,
  ]);

  // upload photo api call
  const props = {
    headers: {
      authorization: bearerToken,
    },
    action: uploadPhotoUrl,
    name: 'images',
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || file.response[0].imageUrl);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    let onlyKeepOneImg = [...newFileList];
    onlyKeepOneImg = onlyKeepOneImg.slice(-1);
    setFileList(onlyKeepOneImg);

    if (onlyKeepOneImg[0]?.status === 'done' && onlyKeepOneImg[0]?.uid !== 'originalBannerImage') {
      form.setFieldsValue({
        bannerImageUrl: onlyKeepOneImg[0]?.response[0].imageUrl,
        bannerImageId: onlyKeepOneImg[0]?.response[0].imageId,
      });
    }
  };

  return (
    <div className="bg-white my-6 py-4 px-6">
      <Form name="dynamic_form_item" onFinish={onFinish} style={{ maxWidth: 600 }} form={form}>
        <Form.Item
          label="Banner image"
          name="bannerImageUrl"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Missing image.',
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            onPreview={handlePreview}
            fileList={fileList}
            onChange={handleChange}
            onRemove={(e) => {
              if (e.uid !== 'originalBannerImage') {
                dispatch(deleteImage([e.response[0].imageId]));
              }
              form.setFieldsValue({ bannerImageUrl: '', bannerImageId: 'no image please upload' });
            }}
            {...props}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
          <Form.Item label="Image Id" name="bannerImageId">
            <span>{imageId ?? 'Original Image'}</span>
          </Form.Item>
          <span>Suggested Image Dimensions: 720 x 400 pixels</span>
          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Form.Item>
        <Form.Item
          label="Promotion category"
          name="promotionCategory"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
              whitespace: true,
              message: 'Missing input.',
            },
          ]}
        >
          <Input placeholder="category" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item>
          <Collapse>
            <Panel header="Pinned Live Show" key="1" forceRender>
              <Form.List name="pinnedLiveShowMediaUrls">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Pinned Live Show Url(s)' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input url.',
                            },
                          ]}
                          noStyle
                        >
                          <Input placeholder="url" style={{ width: '60%' }} />
                        </Form.Item>
                        <DeleteIconButton onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                      >
                        Pin Live Show URL
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Panel>
            <Panel header="Promotion Live Show" key="2" forceRender>
              <Form.List name="promotionLiveShowMediaUrls">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Promotion Live Show Url(s)' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please input url.',
                            },
                          ]}
                          noStyle
                        >
                          <Input placeholder="url" style={{ width: '60%' }} />
                        </Form.Item>
                        <DeleteIconButton onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                      >
                        Promotion Live Show URL
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LiveShow;
