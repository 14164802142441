import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Input, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getCategories, getMallCategories } from '@/Redux/slices/PostManagementSlice';
import { createChannel } from '@/Redux/slices/VodSlice';

interface ModalProps {
  isModalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
}

const CreateChannelModal = ({ isModalVisible, setModalVisible }: ModalProps) => {
  const dispatch = useAppDispatch();
  const categoryData = useAppSelector((state) => state.postManagement.value.categories);
  const { mallCategories } = useAppSelector((state) => state.postManagement.value);
  const userName = useAppSelector((state) => state.account.user.username);

  const [form] = useForm();
  const formValues = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    if (userName) {
      dispatch(getCategories());
      dispatch(getMallCategories());
    }
  }, [dispatch, userName]);

  useEffect(() => {
    form
      .validateFields()
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  const selectOptions = useMemo(
    () =>
      (categoryData || []).map((c) => ({
        label: c.name,
        value: c.categoryId,
      })),
    [categoryData],
  );

  const mallCategorySelectOptions = useMemo(
    () =>
      (mallCategories || []).map((c) => ({
        label: c.name,
        value: c.categoryId,
      })),
    [mallCategories],
  );

  const onChannelCreate = () => {
    const payload = {
      channelName: formValues.channel,
      disabled: formValues.disabled,
      categoryIds: formValues.categoryIds || [],
      mallCategoryCode: formValues.mallCategoryCode,
    };

    form.resetFields();
    setModalVisible(false);
    dispatch(createChannel(payload));
  };

  const onModalClose = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form, setModalVisible]);

  return (
    <Modal
      title="Create Channel"
      open={isModalVisible}
      closable
      onCancel={onModalClose}
      okText="Create"
      onOk={onChannelCreate}
      okButtonProps={{ htmlType: 'submit', disabled: !submittable }}
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          name="channel"
          label="Channel"
          rules={[
            {
              min: 1,
              required: true,
              message: 'Channel name cannot be empty',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="disabled" label="Disabled" valuePropName="checked" initialValue>
          <Switch />
        </Form.Item>
        <Form.Item name="categoryIds" label="Categories">
          <Select mode="multiple" allowClear placeholder="Categories" options={selectOptions} />
        </Form.Item>
        <Form.Item
          name="mallCategoryCode"
          label="Mall Category"
          rules={[{ required: true, message: 'Please select.' }]}
        >
          <Select placeholder="Mall Category" options={mallCategorySelectOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateChannelModal;
