import React, { useEffect } from 'react';

import { Form, Space, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { WhitelistDomainForm } from '@/Interfaces/I_WhitelistDomain';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getWhitelistDomain, postWhiteListDomain } from '@/Slices/WhitelistDomainSlice';

const WhitelistDomains = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const whitelistDomains = useAppSelector((state) => state.whitelistDomain.values);

  const onFinish = (values: WhitelistDomainForm) => {
    dispatch(postWhiteListDomain(values.whitelistDomains));
  };

  useEffect(() => {
    dispatch(getWhitelistDomain());
  }, [dispatch]);
  useEffect(() => {
    form.setFieldsValue({
      whitelistDomains,
    });
  }, [form, whitelistDomains]);

  return (
    <div className="bg-white my-6 py-4 px-6">
      Domain List
      {/* remind */}
      <div>
        <ul className="list-none pl-0 text-gray-500">
          <li>
            * Protocol in host name MUST be excluded, i.e. https://www.hktvmall.com, please input
            www.hktvmall.com.
          </li>
        </ul>
      </div>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        autoComplete="off"
      >
        <Form.List name="whitelistDomains">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    name={[name, 'domainName']}
                    rules={[{ required: true, message: 'Missing domain name.' }]}
                  >
                    <Input placeholder="Domain name" />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'hostName']}
                    rules={[{ required: true, message: 'Missing host name.' }]}
                  >
                    <Input placeholder="Host name" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  add whitelist domain
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WhitelistDomains;
