import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { FetchUserRoleParam, PutUserRoleParam } from '@/Interfaces/I_userRole';

import { fetchUserRoleApi, putUserRoleApi } from '@/Axios/api/userRole';

import { getUserRole, putUserRole, setUserRole } from '../slices/UserRoleSlice';

function* handleGetUserRole(action: PayloadAction<FetchUserRoleParam>) {
  try {
    const data: AxiosResponse = yield call(fetchUserRoleApi, action.payload);
    yield put(setUserRole(data));
  } catch (e) {
    // error
  }
}

function* handlePutUserRole(action: PayloadAction<PutUserRoleParam[]>) {
  try {
    yield call(putUserRoleApi, action.payload);
    message.success({
      content: 'User account added',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    const err = e as AxiosError;
    message.error({
      content: err.response?.data.message,
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetUserRole() {
  yield takeLatest(getUserRole.type, handleGetUserRole);
}

export function* watchPutUserRole() {
  yield takeEvery(putUserRole.type, handlePutUserRole);
}
