import { customAxios } from '../index';

export interface LoginPayloadData {
  grant_type: string;
  username: string;
  password: string;
}

export interface LoginResponseData {
  access_token: string;
  principal: {
    username: string;
  };
}

export interface LogoutPayloadData {
  token: string;
}

// 登入
export const postUserLoginAPI = (data: LoginPayloadData) => {
  const formData: string = (Object.keys(data) as Array<keyof typeof data>)
    .map((key) => `${key}=${encodeURIComponent(data[key] as string)}`)
    .join('&');

  return customAxios({
    url: 'hktv_koc/proxy/sso/oauth/token',
    method: 'POST',
    data: formData,
  });
};

// 登出
export const postUserLogoutAPI = (data: LogoutPayloadData) =>
  customAxios({
    url: 'hktv_koc/proxy/sso/user/logout',
    method: 'POST',
    data: {
      ...data,
    },
  });
