import React from 'react';

import Tab from '@/Modules/tab/Tab';

import KocBudgetPool from './KocBudgetPool';
import VodBudgetPool from './VodBudgetPool';

const Index = () => (
  <Tab
    defaultActiveKey="1"
    initialPanes={[
      { title: 'KOC', content: <KocBudgetPool />, key: '1' },
      { title: 'VOD Buy', content: <VodBudgetPool />, key: '2' },
    ]}
  />
);

export default Index;
