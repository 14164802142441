import { message } from 'antd';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { PostWhitelistDomain } from '@/Interfaces/I_WhitelistDomain';

import {
  getWhitelistDomain,
  setWhitelistDomain,
  postWhiteListDomain,
  postWhiteListDomainSccuss,
} from '@/Slices/WhitelistDomainSlice';

import { getWhitelistDomainAPI, postWhitelistDomainAPI } from '@/Axios/api/whitelistDomain';

function* handleGetWhitelistDomain() {
  try {
    const data: AxiosResponse = yield call(getWhitelistDomainAPI);
    yield put(setWhitelistDomain(data.data));
  } catch (e) {
    message.error({
      content: 'Fail to get whitelist domain.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePostWhitelistDomain(action: PayloadAction<PostWhitelistDomain[]>) {
  try {
    yield call(postWhitelistDomainAPI, action.payload);
    yield put(postWhiteListDomainSccuss());
    message.success({
      content: 'Updated whitelist domain success.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to update whitelist domain.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetWhitelistDomain() {
  yield takeEvery(getWhitelistDomain.type, handleGetWhitelistDomain);
}
export function* watchPostWhitelistDomain() {
  yield takeEvery(postWhiteListDomain.type, handlePostWhitelistDomain);
}
