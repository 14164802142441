import { AxiosResponse } from 'axios';
import { TermsAndConditionsParam,  PutTermsAndConditionsPayload } from '@/Interfaces/I_TermsAndConditions';
import { customAxios } from '../index';

interface Response<T> {
  data: T;
  status: {
    code: string;
    message: string;
  };
}

const getTncAPI = (data: TermsAndConditionsParam): Promise<AxiosResponse<Response<string[]>>> =>
  customAxios({url:'/hktv_koc/cms/termsAndConditions', method: "GET", params: data});

const updateTncAPI = (data: PutTermsAndConditionsPayload): Promise<AxiosResponse<Response<string[]>>>  => 
      customAxios({url:'/hktv_koc/cms/termsAndConditions', method: "PUT", data});

  

export { getTncAPI, updateTncAPI };
