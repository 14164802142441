import { createSlice } from '@reduxjs/toolkit';

import { mallCategory } from '@/Interfaces/I_setting';

const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    value: {
      mallCategories: [] as mallCategory[],
    },
    loading: false,
    error: {},
  },
  reducers: {
    getSettings: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setSettings: (state, action) => {
      const assignState = state;
      assignState.value = action.payload;
      assignState.loading = false;
    },
    updateMallCategorySetting: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateMallCategorySettingSuccess: (state, action) => {
      const assignState = state;
      const { mallCategoryCode, landingEnable } = action.payload;
      const { mallCategories: oldData } = state.value;
      const newData = [...oldData];
      const mallCategorySettingIndex = newData.findIndex((e) => e.code === mallCategoryCode);
      assignState.value.mallCategories[mallCategorySettingIndex] = {
        ...assignState.value.mallCategories[mallCategorySettingIndex],
        landingDisabled: !landingEnable,
      };
      assignState.loading = false;
    },
    fail: (state, action) => {
      const assignState = state;
      assignState.error = action.payload;
      assignState.loading = false;
    },
  },
});

export const {
  getSettings,
  setSettings,
  updateMallCategorySetting,
  updateMallCategorySettingSuccess,
  fail,
} = settingSlice.actions;

export default settingSlice.reducer;
