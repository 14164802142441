/* eslint-disable no-console */
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  loginStart,
  loginSuccess,
  logoutStart,
  logoutSuccess,
  setLoading,
  setLoginError
} from '@/Slices/AccountSlice';

import {
  LoginPayloadData,
  LoginResponseData,
  LogoutPayloadData,
  postUserLoginAPI,
  postUserLogoutAPI
} from '@/Axios/api/account';

interface Response<T> {
  data: T;
  status: string;
  statusText: string;
}

interface LoginPayload extends LoginPayloadData {
  remember: {
    saved: boolean;
    username: string;
    password: string;
  };
}

// Handler Functions

function* handleUserLogin(action: PayloadAction<LoginPayload>) {
  const { username, password, remember } = action.payload;
  yield put(setLoading(true));

  try {
    const { data }: Response<LoginResponseData> = yield call(postUserLoginAPI, {
      grant_type: 'password',
      username,
      password,
    });
    yield put(loginSuccess({ ...data, remember: { ...remember } }));
  } catch (error: unknown) {
    const err = error as AxiosError;
    yield put(setLoginError(err.response?.data));
  }

  yield put(setLoading(false));
}

function* handleUserLogout(action: PayloadAction<LogoutPayloadData>) {
  const { token } = action.payload;

  try {
    yield call(postUserLogoutAPI, { token });
  } catch (error) {
    console.error(error);
  }

  yield put(logoutSuccess());
}

// Watchers

// 登入
export function* watchUserLogin() {
  yield takeLatest(loginStart.type, handleUserLogin);
}

// 登出
export function* watchUserLogout() {
  yield takeLatest(logoutStart.type, handleUserLogout);
}
