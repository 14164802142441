import Tab from '@/Modules/tab';
import React, { useState } from 'react';
import CollectionPostList from './CollectionPostList';
import CollectionList from './CollectionList';

const Collections = () => {
  const [activeKey, setActiveKey] = useState('1');

  return (
    <Tab
      activeKey={activeKey}
      onChange={setActiveKey}
      items={[
        {
          key: '1',
          label: 'Collection',
          children: <CollectionList active={activeKey === '1'} />,
        },
        {
          key: '2',
          label: 'Edit Posts',
          children: <CollectionPostList active={activeKey === '2'} />,
        },
      ]}
    />
  );
};

export default Collections;
