import axios from 'axios';
import Cookies from 'js-cookie';

const initToken = Cookies.get('accessToken') || '';

// Get domain by ENV
const getDomainByEnv = () => {
  const API_DOMAIN: { [key: string]: string } = {
    dev: 'https://koc-backend-dev.gcp.hkmpcl.com.hk/',
    staging: 'https://koc-backend-staging.gcp.hkmpcl.com.hk/',
    prod: 'https://backend.koc.hktvmall.com/',
  };

  let env = process.env.GATSBY_ENV || 'dev';

  if (env === 'development') {
    env = 'dev';
  } else if (env === 'production') {
    env = 'prod';
  }

  console.log(process.env.GATSBY_ENV, env);
  return API_DOMAIN[env];
};

export const getFrontEndDomainByEnv = () => {
  const FRONTEND_API_DOMAIN: { [key: string]: string } = {
    dev: 'https://koc-app-dev.gcp.hkmpcl.com.hk/',
    staging: 'https://koc-app-staging.gcp.hkmpcl.com.hk/',
    prod: 'https://koc.hktvmall.com/',
  };

  let env = process.env.GATSBY_ENV || 'dev';

  if (env === 'development') {
    env = 'dev';
  } else if (env === 'production') {
    env = 'prod';
  }

  return FRONTEND_API_DOMAIN[env];
};

export const customAxios = axios.create({
  baseURL: getDomainByEnv(),
});
customAxios.defaults.headers.common.Authorization = `Bearer sso-${initToken}`;

export function addAxiosToken(token: string) {
  const ssoToken = `sso-${token}`;
  customAxios.defaults.headers.common.Authorization = `Bearer ${ssoToken}`;
}

export function clearAxiosToken() {
  customAxios.defaults.headers.common.Authorization = '';
}

// category 相關 api
export const searchCategory = (
  data: any,
  mainIndex: 'main_category' | 'sub_category' | 'tag_category',
) =>
  customAxios({
    url: `aprdutils/${mainIndex}/_search`,
    method: 'POST',
    ...data,
  });

// 圖片相關 api
export const uploadImage = (data: any) =>
  customAxios({
    url: '/aprdutils/image/upload',
    method: 'POST',
    ...data,
  });

export const deleteImage = (data: any, deleteImgName: string) =>
  customAxios({
    url: `aprdutils/image/delete/${deleteImgName}`,
    method: 'DELETE',
    ...data,
  });
