import React from 'react';
import './reasonCard.scss';

import { Checkbox, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

const { TextArea } = Input;

interface ReasonCardProps {
  /**
   * form instance
   */
  form: FormInstance;
  /**
   *  checkBox Array
   */
  checkBoxArray?: { id: number | string; content: string }[];
  /**
   * set if has value or not
   */
  setHasValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReasonCard = ({
  checkBoxArray = [
    { id: 1, content: 'Sex, violence' },
    { id: 2, content: 'Hate Speech' },
    { id: 3, content: 'Untrue information' },
    { id: 4, content: 'Harass, bullying' },
    { id: 5, content: 'Infringing' },
    { id: 6, content: 'Plagiarism' },
    { id: 7, content: 'Advertisement' },
  ],
  form,
  setHasValue,
}: ReasonCardProps) => (
  <Form
    className="reasonCard--root"
    form={form}
    layout="vertical"
    onValuesChange={(changedValues) => {
      if (changedValues.input !== '') {
        setHasValue(true);
      }
    }}
  >
    <Form.Item name="reason">
      <Checkbox.Group
        className="reasonCard--checkboxGroup"
        onChange={(changedValues) => {
          if (changedValues.length === 0) setHasValue(false);
          if (!changedValues.includes('other')) {
            form.resetFields(['input']);
          }
        }}
      >
        {checkBoxArray.map((item) => (
          <Row key={item.id} className="reasonCard--checkboxGroup--row">
            <Checkbox value={item.id}>{item.content}</Checkbox>
          </Row>
        ))}
        <Row className="reasonCard--checkboxGroup--other">
          <Col>
            <Checkbox value="other">Other:</Checkbox>
          </Col>
          <Col className="reasonCard--checkboxGroup--col">
            <Form.Item
              name="input"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('reason') &&
                      getFieldValue('reason').includes('other') &&
                      (value?.trim() === '' || value === undefined)
                    ) {
                      return Promise.reject(new Error('Please fill in the reason.'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TextArea
                className="reasonCard--textArea"
                rows={2}
                autoSize={false}
                onChange={() => {
                  if (form.getFieldValue('reason') === undefined) {
                    form.setFieldsValue({
                      reason: ['other'],
                    });
                  } else if (!form.getFieldValue('reason').includes('other'))
                    form.setFieldsValue({
                      reason: ['other', ...form.getFieldValue('reason')],
                    });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Checkbox.Group>
    </Form.Item>
  </Form>
);
export default ReasonCard;
