import { CollectionPost, PostCollection } from '@/Interfaces/I_PostCollection';
import { createSlice } from '@reduxjs/toolkit';

const postCollectionSlice = createSlice({
  name: 'postCollectionSlice',
  initialState: {
    value: {
      collections: [] as PostCollection[],
      currentPostCollection: {
        uuid: '',
        posts: [] as CollectionPost[],
      },
    },
    loading: false,
    error: '',
  },
  reducers: {
    getCollections: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setCollections: (state, action) => {
      const assignState = state;
      assignState.value.collections = [...action.payload.data];
      assignState.loading = false;
    },
    createCollection: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    addNewCollection: (state, action) => {
      const assignState = state;
      assignState.loading = false;
      assignState.value.collections = [...assignState.value.collections, action.payload.data];
    },
    updateCollections: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setErrorMessage: (state, action) => {
      const assignState = state;
      assignState.loading = false;
      assignState.error = action.payload;
    },
    getCollectionPosts: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setCollectionPosts: (state, action) => {
      const assignState = state;
      assignState.loading = false;
      assignState.value.currentPostCollection = action.payload.data;
    },
    createCollectionPosts: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    deleteCollectionPost: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateCollectionPosts: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
  },
});

export const {
  getCollections,
  setCollections,
  createCollection,
  addNewCollection,
  updateCollections,
  setErrorMessage,
  getCollectionPosts,
  setCollectionPosts,
  createCollectionPosts,
  updateCollectionPosts,
  deleteCollectionPost,
} = postCollectionSlice.actions;

export default postCollectionSlice.reducer;
