import { message } from 'antd';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { getLiveShowInfoAPI, postLiveShowInfoAPI } from '@/Axios/api/LiveShow';

import { PostLiveShowInfo } from '@/Interfaces/I_LiveShow';

import { deleteKocImage } from '@/Axios/api/Media';

import {
  getLiveShowInfo,
  setLiveShowInfo,
  updateLiveShowInfo,
  updateLiveShowInfoSuccess,
  deleteImage,
} from '../slices/LiveShowSlice';

function* handleGetLiveShowInfo() {
  try {
    const data: AxiosResponse = yield call(getLiveShowInfoAPI);
    yield put(setLiveShowInfo(data.data));
  } catch (e) {
    message.error({
      content: 'Fail to get live show info.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleUpdateLiveShowInfo(action: PayloadAction<PostLiveShowInfo>) {
  try {
    yield call(postLiveShowInfoAPI, action.payload);
    yield put(updateLiveShowInfoSuccess());
    message.success({
      content: 'Updated live show info.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to update live show info.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleDeleteImage(action: PayloadAction<string[]>) {
  try {
    yield call(deleteKocImage, action.payload);
  } catch (e) {
    message.error({
      content: 'Fail to delete KOC server image.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetLiveInfo() {
  yield takeEvery(getLiveShowInfo.type, handleGetLiveShowInfo);
}

export function* watchLiveShowInfo() {
  yield takeEvery(updateLiveShowInfo.type, handleUpdateLiveShowInfo);
}

export function* watchDeleteImage() {
  yield takeEvery(deleteImage.type, handleDeleteImage);
}
