import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { reportParam } from '@/Interfaces/I_report';

import fetchReportPostAPI from '@/Axios/api/report';

import {
  getReportPost,
  setHidLogPost,
  setIgnoredLogPost,
  setOutstandingPost
} from '../slices/ReportSlice';

function* handleGetPost(action: PayloadAction<reportParam>) {
  try {
    const data: AxiosResponse = yield call(fetchReportPostAPI, action.payload.params);
    if (action.payload.pageName === 'outstanding') yield put(setOutstandingPost(data));
    if (action.payload.pageName === 'hidLog') yield put(setHidLogPost(data));
    if (action.payload.pageName === 'ignoredLog') yield put(setIgnoredLogPost(data));
  } catch (e) {
    // console.log(e);
  }
}

export default function* watchGetPost() {
  yield takeLatest(getReportPost.type, handleGetPost);
}
