import React from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import './deleteIconButton.scss';

interface DeleteIconButtonProps {
  onClick: () => void;
}

const DeleteIconButton = ({ onClick }: DeleteIconButtonProps) => (
  <MinusCircleOutlined className="dynamic-delete-button" onClick={onClick} />
);

export default DeleteIconButton;
