import React from 'react';

import { Col, Row, Space, Switch } from 'antd';

import { useAppDispatch } from '@/Hooks/useAppRedux';
import { mallCategory } from '@/Interfaces/I_setting';

import { updateMallCategorySetting } from '@/Redux/slices/SettingSlice';

interface MallCategoriesSettingsProps {
  mallCategories: mallCategory[];
}

const MallCategoriesSettings = ({ mallCategories }: MallCategoriesSettingsProps) => {
  const dispatch = useAppDispatch();
  const updateMallCategory = (checked: boolean, mallCategoryCode: string) => {
    dispatch(updateMallCategorySetting({ landingEnable: checked, mallCategoryCode }));
  };

  return (
    <Space direction="vertical">
      {mallCategories?.map((category: mallCategory) => (
        <Row>
          <Col span={20}>{category.nameEng}</Col>
          <Col span={4}>
            <Switch
              checked={!category.landingDisabled}
              onChange={(checked) => updateMallCategory(checked, category.code)}
            />
          </Col>
        </Row>
      ))}
    </Space>
  );
};

export default MallCategoriesSettings;
