import React, { useState } from 'react';

import { Button, Input, Modal, Table } from 'antd';

import { useAppDispatch } from '@/Hooks/useAppRedux';

import { putUserRole } from '@/Redux/slices/UserRoleSlice';

import UserRoleRadioOptions from '@/Pages/UserRoleManagement/UserRoleRadioOptions';

const UserRoleAccountModal = ({ isAddNewRoleVisible, setIsAddNewRoleVisible }: any) => {
  const dispatch = useAppDispatch();
  const [accountRoles, setAccountRoles] = useState([
    {
      key: 1,
      hybrisId: '',
      kocUserRole: 'OFFICIAL',
    },
  ]);

  const addNewAccountRolesRow = (row: number) => {
    setAccountRoles((prevAccountRoles) => [
      ...prevAccountRoles,
      {
        key: row,
        hybrisId: '',
        kocUserRole: 'OFFICIAL',
      },
    ]);
  };

  const updateAccountRolesByKey = (key: number, updateFunc: any) => {
    const newData = accountRoles;
    const accountRolesIndex = newData.findIndex((e) => e.key === key);
    newData[accountRolesIndex] = updateFunc({ ...newData[accountRolesIndex] });
    setAccountRoles(newData);
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: 56,
    },
    {
      title: 'User PKey',
      dataIndex: 'user',
      key: 'user',
      render: (hybrisId: string, record: any, index: number) => (
        <Input
          allowClear
          onChange={(e: React.ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            updateAccountRolesByKey(index + 1, (oldAccountRoles: any) => ({
              ...oldAccountRoles,
              hybrisId: target.value,
            }));
          }}
        />
      ),
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      key: 'userRole',
      width: '40%',
      render: (item: any, record: any, index: number) => (
        <UserRoleRadioOptions
          userPk=""
          userRole="OFFICIAL"
          setUserRoleForm={(radioValue: any) => {
            updateAccountRolesByKey(index + 1, (oldAccountRoles: any) => ({
              ...oldAccountRoles,
              kocUserRole: radioValue,
            }));
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Add New Role Account"
      visible={isAddNewRoleVisible}
      onCancel={() => setIsAddNewRoleVisible(false)}
      width={856}
      bodyStyle={{ padding: 0 }}
      footer={null}
    >
      <div className="h-auto bg-white p-6 pb-2">
        <div className="flex justify-end">
          <Button
            type="primary"
            onClick={() => addNewAccountRolesRow(accountRoles.length + 1)}
            disabled={accountRoles.length > 4}
          >
            Add New
          </Button>
        </div>
        <Table
          className="mt-4"
          columns={columns}
          dataSource={accountRoles.map((data) => ({
            key: data.key,
            hybrisId: data.hybrisId,
            userRole: data.kocUserRole,
          }))}
          pagination={false}
        />
        <div className="flex justify-end pt-6">
          <Button
            type="primary"
            onClick={() => {
              const putUserRoleData = accountRoles
                .filter((data) => data.hybrisId !== '')
                .map(({ key, ...data }) => data);
              dispatch(putUserRole(putUserRoleData));
              setIsAddNewRoleVisible(false);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserRoleAccountModal;
