/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLAttributes } from 'react';
import { Form, Input, InputNumber, Switch, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload';
import DndEditableTableImageCell from './DndEditableTableImageCell';

// for image input type, currently only support developer to specifiy the upload image url and required headers, if any
export interface DndEditableTableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  dataIndex: string | string[];
  inputType: 'number' | 'text' | 'boolean' | 'image';
  editable: boolean;
  editing: boolean;
  value: any;
  uploadImageProps?: {
    action: UploadProps['action'];
    headers: UploadProps['headers'];
    name: UploadProps['name'];
    onUploadSuccess?: (file: UploadFile) => void;
  };
}

const DndEditableTableCell = (props: DndEditableTableCellProps) => {
  const { children, dataIndex, inputType, editable, editing } = props;

  if (inputType === 'image') {
    return <DndEditableTableImageCell {...props} />;
  }

  if (inputType === 'boolean') {
    return (
      <td {...props}>
        {editable && editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }} valuePropName="checked">
            <Switch />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  return (
    <td {...props}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {inputType === 'text' ? <Input /> : <InputNumber />}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default DndEditableTableCell;
