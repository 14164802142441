import React from 'react';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { withPrefix } from 'gatsby-link';
import store from '@/Redux/configureStore';
import client from '@/Apollo/Client';

import PrivateRoute from '@/Components/PrivateRoute';

import HomePage from '@/Pages/index';
import ErrorPage from '@/Pages/404';
import HealthPage from '@/Pages/health';

import LayoutComponent from '@/Components/layout/Layout';
import AccountLayoutComponent from '@/Components/layout/Account';
import Seo from '@/Components/Seo';
import PageTitle from '@/Modules/pageTitle/PageTitle';
import accountRouters from './src/routes/Account';
import allRouters from './src/routes';

import './src/styles/main.scss';

const setMainRouters = ({ breadcrumb, Component }) => {
  let path = '';
  let breadcrumbName = '';

  breadcrumb.forEach((item) => {
    path += item.path;
    breadcrumbName = item.breadcrumbName;
  });

  return (
    <PrivateRoute
      key={path}
      path={withPrefix(path)}
      needAuth
      component={() => (
        <LayoutComponent>
          <Seo title={breadcrumbName} />
          <PageTitle title={breadcrumbName} />
          <Component />
        </LayoutComponent>
      )}
    />
  );
};

const setAccountRouters = ({ breadcrumb, needAuth, Component }) => {
  const { path, breadcrumbName } = breadcrumb[0];

  return (
    <PrivateRoute
      key={path}
      path={path}
      needAuth={needAuth}
      component={() => (
        <AccountLayoutComponent>
          <Seo title={breadcrumbName} />
          <Component />
        </AccountLayoutComponent>
      )}
    />
  );
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Router className="wrap-root">
        <HomePage path="/" />
        {allRouters.map((router) => setMainRouters(router))}
        {accountRouters.map((router) => setAccountRouters(router))}
        <HealthPage path="/health" />
        <ErrorPage default />
      </Router>
    </ApolloProvider>
  </Provider>
);
