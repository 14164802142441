import React from 'react';

import { Layout } from 'antd';

import { useAppSelector } from '@/Hooks/useAppRedux';

import LoadingComponent from '@/Components/Loading';

const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
}

const LoginLayoutComponent: React.FC<IProps> = ({ children }: IProps) => {
  const { isLoading } = useAppSelector((state) => state.account);

  return (
    <Layout className="min-h-screen">
      <Content className="min-h-200 pt-c-120 pb-c-73 clg:mx-24">{children}</Content>
      <div className="mt-16">
        <p className="text-info text-center text-text-black2">
          Copyright ©2022 Shoalter Technology Limited. All Rights Reserved.
        </p>
      </div>
      {isLoading && <LoadingComponent />}
    </Layout>
  );
};

export default LoginLayoutComponent;
