import Vod from '@/Pages/Vod';

const VodRoutes = [
  {
    breadcrumb: [{ path: '/vod', breadcrumbName: 'Channel Management' }],
    Component: Vod,
  },
];

export default VodRoutes;
