import React from 'react';
import './pageHeader.scss';

import { Layout } from 'antd';
import { navigate } from 'gatsby-link';

import LogoImg from '../../../images/icon/kocLogo.svg';
import logout from '../../../images/icon/logout.svg';

const { Header: HeaderAnt } = Layout;

interface PageHeaderProps {
  /**
   *  User's name.
   */
  username?: string;
  /**
   *  Optional click handler. Will be triggered when click logo.
   */
  onLogoClick?: () => void;
  /**
   *  Optional click handler. Will be triggered when click or username.
   */
  onUserClick?: () => void;
  /**
   *  Optional click handler. Will be triggered when click logout icon.
   */
  onLogoutClick?: () => void;
}

const PageHeader = ({
  username = '',
  onLogoClick = () => {
    navigate('/');
  },
  onUserClick,
  onLogoutClick,
}: PageHeaderProps) => (
  <HeaderAnt id="header" className="header--root">
    <div onClick={onLogoClick} aria-hidden>
      <img src={LogoImg} alt="" className="header--logo" />
    </div>
    <div aria-hidden className="header--user">
      <span aria-hidden onClick={onUserClick}>
        {username}
      </span>
      <div aria-hidden onClick={onLogoutClick} className="header--logout">
        <img src={logout} alt="" className="header--logout--img" />
      </div>
    </div>
  </HeaderAnt>
);

export default PageHeader;
