import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ValueProps {
  censored: string[];
  updateCode: number;
}
const censoredSlice = createSlice({
  name: 'censorship',
  initialState: {
    value: {
      censored: [],
      updateCode: 0,
    } as ValueProps,
    loading: false,
    error: '',
  },
  reducers: {
    getCensored: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateCensored: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, updateCode: action.payload.updateCode };
      assignState.loading = true;
      assignState.error = '';
    },
    setCensored: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, censored: action.payload };
      assignState.loading = false;
    },
    fail: (state, action) => {
      const assignState = state;
      assignState.error = action.payload;
      assignState.loading = false;
    },
  },
});
export const { getCensored, setCensored, updateCensored, fail } = censoredSlice.actions;

export default censoredSlice.reducer;
