/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import { PaginationData, PostManagementData } from '@/Interfaces/I_postManagement';
import { ChannelCategoryData, ChannelData } from '@/Interfaces/I_vod';

export const vodSlice = createSlice({
  name: 'vodSlice',
  initialState: {
    value: {
      channels: [] as ChannelData[],
      selectedChannel: {} as ChannelData,
      selectedChannelCategory: [] as ChannelCategoryData[],
      post: {
        data: [] as PostManagementData[],
        pagination: {} as PaginationData,
      },
    },
    loading: false,
    error: '',
  },
  reducers: {
    getAllChannel: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setAllChannel: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, channels: action.payload.data };
      assignState.loading = false;
    },
    setSelectedChannel: (_state, _action) => {},
    setSelectedChannelSuccess: (state, action) => {
      const assignState = state;

      const selectedChannelData =
        assignState.value.channels.find((channel) => channel.channelId === action.payload) ||
        ({} as ChannelData);
      assignState.value = { ...assignState.value, selectedChannel: selectedChannelData };
    },
    postChannelPost: (_state, _action) => {},
    postChannelPostSuccess: (state, action) => {
      const assignState = state;
      const { channelId, postUuid } = action.payload;

      if (channelId === assignState.value.selectedChannel.channelId) {
        postUuid.forEach((uuid: string) => {
          const postIdx = assignState.value.post.data.findIndex((post) => post.uuid === uuid);
          assignState.value.post.data[postIdx].channelPinned = true;
        });
      }
    },
    deleteChannelPost: (_state, _action) => {},
    deleteChannelPostSuccess: (state, action) => {
      const assignState = state;
      const { channelId, postUuid } = action.payload;

      if (channelId === assignState.value.selectedChannel.channelId) {
        postUuid.forEach((uuid: string) => {
          const postIdx = assignState.value.post.data.findIndex((post) => post.uuid === uuid);
          assignState.value.post.data[postIdx].channelPinned = false;
        });
      }
    },
    getChannelCategory: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setChannelCategory: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, selectedChannelCategory: action.payload.data };
      assignState.loading = false;
    },
    getVodPost: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setVodPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, post: action.payload.data };
      assignState.loading = false;
    },
    createChannel: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    createChannelSuccess: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, channels: action.payload.data };
      assignState.loading = false;
    },
    updateChannels: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateChannelsSuccess: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, channels: action.payload.data };
      assignState.loading = false;
    },
    failure: (state, _action) => {
      const assignState = state;
      assignState.loading = false;
    },
  },
});

export const {
  getAllChannel,
  setAllChannel,
  setSelectedChannel,
  setSelectedChannelSuccess,
  postChannelPost,
  postChannelPostSuccess,
  deleteChannelPost,
  deleteChannelPostSuccess,
  getChannelCategory,
  setChannelCategory,
  getVodPost,
  setVodPost,
  createChannel,
  createChannelSuccess,
  updateChannels,
  updateChannelsSuccess,
  failure,
} = vodSlice.actions;

export default vodSlice.reducer;
