import { createSlice } from '@reduxjs/toolkit';

import { CampaignData } from '@/Interfaces/I_Campaign';
import { PaginationData } from '@/Interfaces/I_postManagement';

const CampaignSlice = createSlice({
  name: 'campaignSlice',
  initialState: {
    value: {
      campaignData: {
        data: [] as CampaignData[],
        pagination: {} as PaginationData,
      },
    },
    loading: false as boolean,
  },
  reducers: {
    getCampaignData: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setCampaignData: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, campaignData: action.payload };
      assignState.loading = false;
    },
    postCampaignData: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    postCampaignDataSuccess: (state, action) => {
      const assignState = state;
      assignState.value.campaignData.data = [
        action.payload,
        ...assignState.value.campaignData.data,
      ];
      assignState.loading = false;
    },
    patchCampaignData: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    patchCampaignDataSuccess: (state, action) => {
      const assignState = state;
      const { uuid } = action.payload;
      const campaignIdx = assignState.value.campaignData.data.findIndex(
        (campaign) => campaign.uuid === uuid,
      );
      if (campaignIdx !== -1) {
        assignState.value.campaignData.data[campaignIdx] = action.payload;
      }
      assignState.loading = false;
    },
  },
});

export const {
  getCampaignData,
  setCampaignData,
  postCampaignData,
  postCampaignDataSuccess,
  patchCampaignData,
  patchCampaignDataSuccess,
} = CampaignSlice.actions;

export default CampaignSlice.reducer;
