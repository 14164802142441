import { createSlice } from '@reduxjs/toolkit';

const liveShowSlice = createSlice({
  name: 'liveShow',
  initialState: {
    bannerImageUrl: '' as string,
    promotionCategory: '' as string,
    pinnedLiveShowMediaUrls: [] as string[],
    promotionLiveShowMediaUrls: [] as string[],
    loading: false,
    error: {},
  },
  reducers: {
    getLiveShowInfo: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setLiveShowInfo: (state, action) => {
      const assignState = state;
      const {
        bannerImageUrl,
        promotionCategory,
        pinnedLiveShowMediaUrls,
        promotionLiveShowMediaUrls,
      } = action.payload;
      assignState.bannerImageUrl = bannerImageUrl;
      assignState.promotionCategory = promotionCategory;
      assignState.pinnedLiveShowMediaUrls = pinnedLiveShowMediaUrls;
      assignState.promotionLiveShowMediaUrls = promotionLiveShowMediaUrls;
      assignState.loading = false;
    },
    updateLiveShowInfo: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateLiveShowInfoSuccess: (state) => {
      const assignState = state;
      assignState.loading = false;
    },
    deleteImage: (state, action) => state,
  },
});

export const {
  getLiveShowInfo,
  setLiveShowInfo,
  updateLiveShowInfo,
  updateLiveShowInfoSuccess,
  deleteImage,
} = liveShowSlice.actions;

export default liveShowSlice.reducer;
