import React, { useState } from 'react';

import Tab from '@/Modules/tab/Tab';

import VodChannel from './VodChannel';
import ChannelPriority from './ChannelPriority';

const Index = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <Tab
      defaultActiveKey={activeTab}
      initialPanes={[
        {
          title: 'Operating',
          content: <VodChannel />,
          key: '1',
        },
        {
          title: 'Priority',
          content: <ChannelPriority />,
          key: '2',
        },
      ]}
      onChange={(activeKey: string) => setActiveTab(activeKey)}
    />
  );
};

export default Index;
