import { Input } from "antd";
import React, { useEffect, useState } from 'react'

interface SkuTimestampProps {
  timeObject: TimeObject;
  skuIndex: number;
  timestampIndex: number;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => void;
}

interface TimeObject {
  hour: string | undefined,
  minute: string | undefined,
  second: string | undefined,
}

const SkuTimestampEditor = ({skuIndex, timestampIndex, timeObject, onTimeObjectChange}: SkuTimestampProps) => {

  const [isHourError, setIsHourError] = useState(false)
  const [isMinuteError, setIsMinuteError] = useState(false)
  const [isSecondError, setIsSecondError] = useState(false)
  const [hourValue, setHourValue] = useState<string | undefined>("");
  const [minuteValue, setMinuteValue] = useState<string | undefined>("");
  const [secondValue, setSecondValue] = useState<string | undefined>("");

  const hourTest = (hour: string) => {
    const regex = /^(0|[1-9]\d*)$/;
    return regex.test(hour);
  }

  const minuteSecondTest = (time: string) => {
    const regex = /^([0-9]|[1-5][0-9]|59)$/;
    return regex.test(time);
  };

  const onHourChange = (hour: string | undefined) => {
    setHourValue(hour);
    const tempTimeObject = JSON.parse(JSON.stringify(timeObject));
    tempTimeObject.hour = hour;
    let isError = false;
    if (hour !== undefined && hourTest(hour)){
      setIsHourError(false);
    }
      else {
      setIsHourError(true);
      isError = true;
    }
    onTimeObjectChange(skuIndex, timestampIndex, tempTimeObject, isError);
  }

  const OnMinuteChange = (minute: string | undefined) => {
    setMinuteValue(minute);
    const tempTimeObject = JSON.parse(JSON.stringify(timeObject));
    tempTimeObject.minute = minute;
    let isError = false;
    if (minute !== undefined && minuteSecondTest(minute)){
      setIsMinuteError(false);
    }
    else {
      setIsMinuteError(true);
      isError = true;
    }
    onTimeObjectChange(skuIndex, timestampIndex, tempTimeObject, isError);
  }

  const OnSecondChange = (second: string | undefined) => {
    setSecondValue(second);
    const tempTimeObject = JSON.parse(JSON.stringify(timeObject));
    tempTimeObject.second = second;
    let isError = false;
    if (second !== undefined &&  minuteSecondTest(second)){
      setIsSecondError(false);
    }
    else {
      setIsSecondError(true);
      isError = true;
    }
    onTimeObjectChange(skuIndex, timestampIndex, tempTimeObject, isError);
  }

  useEffect(() => {
    if (timeObject !== undefined && timeObject !== null){
      setHourValue(timeObject.hour ? timeObject.hour : "");
      if (hourTest(timeObject.hour ? timeObject.hour : "")){
        setIsHourError(false);
      }
        else {
        setIsHourError(true);
      }
      setMinuteValue(timeObject.minute ? timeObject.minute : "");
      if (minuteSecondTest(timeObject.minute ? timeObject.minute : "")){
        setIsMinuteError(false);
      }
        else {
        setIsMinuteError(true);
      }
      setSecondValue(timeObject.second ? timeObject.second : "");
      if (minuteSecondTest(timeObject.second ? timeObject.second : "")){
        setIsSecondError(false);
      }
        else {
        setIsSecondError(true);
      }
    }
  },[timeObject])

  return (
    <div className="flex-row">
        <Input className="me-2 w-20" placeholder="00" value={hourValue} onChange={(e) => onHourChange(e.target.value)} status={ isHourError ? "error" : ""}/>
      :
        <Input className="ms-2 me-2 w-10"placeholder="00" value={minuteValue} onChange={(e) => OnMinuteChange(e.target.value)} status={ isMinuteError ? "error" : ""}/>
      :
        <Input className="ms-2 w-10" placeholder="00" value={secondValue} onChange={(e) => OnSecondChange(e.target.value)} status={ isSecondError ? "error" : ""}/> :
      
    </div>
  )
}

export default SkuTimestampEditor;