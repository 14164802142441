import { convertTimeObjectToMiliSecond, getTimeObjectFromMiliSecond } from "@/Utils/timeTranslate";
import { useAppDispatch } from "@/Hooks/useAppRedux";
import { Avatar, Button, Input, Modal, Radio, message } from "antd";
import React, { useEffect, useRef, useState } from 'react'
import { UpdatePostSkuParam } from "@/Interfaces/I_modal";
import { updatePostSku } from "@/Redux/slices/ModalSlice";
import SkuTimestampEditor from "./SkuTimestampEditor";
import ConfirmationModal from "./ConfirmationModal";

enum SkuTypeEnum{
  HKTV,
  LITTLEMALL
}

interface EditSkuModalProps {
  isModalVisible : boolean;
  setModalVisible: (value: boolean) => void;
  skusList : Skus[];
  littleMallSkusList: LittleMallSkus[];
  skuTimestampList: SkuTimestamp;
  postUuid: string;
  postOwnerRole?: string;
}

interface MallPostSkusListProps {
  postSkus: Skus[];
  onSkuDeleteClick: (skuIndex: number) => void;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => void;
  onTimestampDeleteClick: (skuIndex: number, timestampIndex: number) => void;
  onAddTimestampClick: (skuIndex: number) => void;
}

interface LittleMallPostSkusListProps {
  postSkus: LittleMallSkus[];
  onSkuDeleteClick: (skuIndex: number) => void;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => void;
  onTimestampDeleteClick: (skuIndex: number, timestampIndex: number) => void;
  onAddTimestampClick: (skuIndex: number) => void;
}

interface AddedSkuListProps {
  addedSkus: AddedSkus[];
  onSkuCodeChange: (skuIndex: number, skuCode: string) => void;
  onSkuDeleteClick: (skuIndex: number) => void;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => void;
  onTimestampDeleteClick: (skuIndex: number, timestampIndex: number) => void;
  onAddTimestampClick: (skuIndex: number) => void;
}

interface FooterProps {
  onSaveClick : () => void;
  onCancelClick : () => void;
}

interface Skus {
  skuCode: string;
  skuName: string;
  skuImageUrl: string;
  skuPackingSpec: string;
  timestamp?: number[];
  timeObject?: TimeObject[];
}

interface LittleMallSkus {
  uuid: string;
  originalPrice: string;
  skuNameEn: string;
  skuNameCh: string;
  sellingPrice: string;
  mainPhoto: string;
  storeCode: string;
  storeSkuId: string;
  followBuy: number;
  visible: boolean;
  online: boolean;
  outOfStock: boolean;
  timestamp?: number[];
  timeObject?: TimeObject[];
}

interface SkuTimestamp {
  skus: AddedSkus[];
  littleMallSkus: AddedSkus[];
}

interface AddedSkus {
  skuCode: string;
  timestamp: number[];
  timeObject?: TimeObject[];
}

interface TimeObject {
  hour: string | undefined,
  minute: string | undefined,
  second: string | undefined,
}

const Footer = ({onSaveClick, onCancelClick}: FooterProps) => (
  <div className="flex flex-row">
    <Button onClick={onSaveClick}>save</Button>
    <Button danger onClick={onCancelClick}>cancel</Button>
  </div>
)

const MallPostSkusList = ({postSkus, onSkuDeleteClick, onTimeObjectChange, onAddTimestampClick, onTimestampDeleteClick}: MallPostSkusListProps) => (
  <div className="postSkusList-container">
    {postSkus && postSkus.map((sku, skuIndex) => (
      <div className="postSkusList-sku-container pb-5">
        <div className="flex flex-row justify-between w-full">
          <div className="postSkusList-skus-info flex-row flex">
            <Avatar size={40} shape="square" src={sku.skuImageUrl} />
            <div className="flex-column ps-2">
              <div className="postSkusList-skus-code font-bold w-80">
                {sku.skuCode}
              </div>
              <div className="postSkusList-sku-name w-80">
                {sku.skuName}
              </div>
            </div>
          </div>
          <Button danger onClick={() => onSkuDeleteClick(skuIndex)}> Delete SKU</Button>
        </div>
        <div className="timestamp-list pt-4">
          {sku.timeObject && sku.timeObject.map((timeObject, timestampIndex) => (
            <div className="timestamp pt-2 flex">
              <SkuTimestampEditor 
                timeObject={timeObject} 
                skuIndex={skuIndex} 
                timestampIndex={timestampIndex} 
                onTimeObjectChange={onTimeObjectChange}
              />
              <Button className="ms-5" type="default" danger onClick={() => onTimestampDeleteClick(skuIndex, timestampIndex)}> - </Button>
            </div>
            ))
          }
          <Button className="text-sky-500 border-sky-500 mt-2" onClick={() => onAddTimestampClick(skuIndex)}>Add Timestamp</Button>
        </div>
      </div>
    ))}
  </div>
)

const LittleMallPostSkusList = ({postSkus, onSkuDeleteClick, onTimeObjectChange, onAddTimestampClick, onTimestampDeleteClick}: LittleMallPostSkusListProps) => (
  <div className="postSkusList-container">
    {postSkus && postSkus.map((sku, skuIndex) => (
      <div className="postSkusList-sku-container pb-5">
        <div className="flex flex-row justify-between w-full">
          <div className="postSkusList-skus-info flex-row flex">
            <Avatar size={40} shape="square" src={sku.mainPhoto} />
            <div className="flex-column ps-2">
              <div className="postSkusList-skus-code font-bold w-80">
                {sku.storeSkuId}
              </div>
              <div className="postSkusList-sku-name w-80">
                {sku.skuNameCh}
              </div>
            </div>
          </div>
          <Button danger onClick={() => onSkuDeleteClick(skuIndex)}> Delete SKU</Button>
        </div>
        <div className="timestamp-list pt-4">
          {sku.timeObject && sku.timeObject.map((timeObject, timestampIndex) => (
            <div className="timestamp pt-2 flex">
              <SkuTimestampEditor 
                timeObject={timeObject} 
                skuIndex={skuIndex} 
                timestampIndex={timestampIndex} 
                onTimeObjectChange={onTimeObjectChange} 
              />
              <Button className="ms-5" type="default" danger onClick={() => onTimestampDeleteClick(skuIndex, timestampIndex)}> - </Button>
            </div>
            ))
          }
          <Button className="text-sky-500 border-sky-500 mt-2" onClick={() => onAddTimestampClick(skuIndex)}>Add Timestamp</Button>
        </div>
      </div>
    ))}
  </div>
)

const AddedSkuList = ({addedSkus, onSkuCodeChange, onSkuDeleteClick, onTimeObjectChange, onAddTimestampClick, onTimestampDeleteClick}:AddedSkuListProps) => (
  <div className="addedSkuList-container">
    {addedSkus && addedSkus.map((sku, skuIndex) => (
      <div className="pb-5">
        <div className="flex flex-row justify-between w-100">
          { sku.skuCode === "" ? 
            <Input className="w-80" placeholder="Enter SKU code" value={sku.skuCode} onChange={(e) => onSkuCodeChange(skuIndex, e.target.value)} status="error"/> :
            <Input className="w-80" placeholder="Enter SKU code" value={sku.skuCode} onChange={(e) => onSkuCodeChange(skuIndex, e.target.value)} />
          }
          <Button danger onClick={() => onSkuDeleteClick(skuIndex)}> Delete SKU</Button>
        </div>
        <div>
          {sku.timeObject && sku.timeObject.map((timeObject, timestampIndex) => (
              <div className="timestamp pt-2 flex">
                <SkuTimestampEditor 
                  timeObject={timeObject} 
                  skuIndex={skuIndex} 
                  timestampIndex={timestampIndex} 
                  onTimeObjectChange={onTimeObjectChange} 
                />
                <Button className="ms-5" type="default" danger onClick={() => onTimestampDeleteClick(skuIndex, timestampIndex)}> - </Button>
              </div>
              ))
            }
            <Button className="text-sky-500 border-sky-500 mt-2" onClick={() => onAddTimestampClick(skuIndex)}>Add Timestamp</Button>
        </div>
      </div>
    ))}
  </div>
)


const EditSkuModal = ({isModalVisible, setModalVisible, skusList, littleMallSkusList, skuTimestampList, postUuid, postOwnerRole}:EditSkuModalProps) => {
  const dispatch = useAppDispatch();
  const [localSkuListState, setLocalSkusListState] = useState<Skus[]>([])
  const [localLittleMallSkusListState, setLocalLittleMallSkusListState] = useState<LittleMallSkus[]>([])
  const [addedSkusList, setAddedSkusList] = useState<AddedSkus[]>([]);
  const [isDeleteSkuModalShow, setIsDeleteSkuModalShow] = useState(false);
  const [ selectedType, setSelectedType ] = useState<SkuTypeEnum>(SkuTypeEnum.HKTV)
  const [isSaveModalShow, setIsSaveModalShow] = useState(false);
  const deleteSkuIndex = useRef<number>();

  const onSaveClick = () => {
    try{
      if (selectedType === SkuTypeEnum.HKTV){
        localSkuListState.forEach((sku) => {
          sku.timestamp?.forEach((timestamp) => {
            if (timestamp < 0 || timestamp === undefined || timestamp === null){
              throw new TypeError("Invalid data in timestamp")
            }
          })
        })
      } else {
        localLittleMallSkusListState.forEach((sku) => {
          sku.timestamp?.forEach((timestamp) => {
            if (timestamp < 0 || timestamp === undefined || timestamp === null){
              throw new TypeError("Invalid data in timestamp")
            }
          })
        })
      }
      addedSkusList.forEach((sku) => {
        if (sku.skuCode === ""){
          throw new TypeError("invalid data in Sku Code")
        }
        sku.timestamp?.forEach((timestamp) => {
          if (timestamp < 0 || timestamp === undefined || timestamp === null){
            throw new TypeError("Invalid data in timestamp")
          }
        })
      })
      setIsSaveModalShow(true)
    }
    catch (e)
   {
      message.error({
        content:`${e}, please check if you have type the right value`,
        duration: 3,
        style: {
          marginTop: '120px',
        },
      })
    }
  }

  const onSaveConfirmClick = () => {
    const payload = {} as UpdatePostSkuParam;
    if (selectedType === SkuTypeEnum.HKTV){
      payload.littleMallSku = false;
      payload.post_uuid = postUuid;
      payload.skus = localSkuListState.map((sku) => (
        {
          skuCode: sku.skuCode,
          timestamp: sku.timestamp || [],
        }
      ))
      payload.skus = payload.skus.concat(addedSkusList);
      dispatch(updatePostSku(payload))
    } else {
      payload.littleMallSku = true;
      payload.post_uuid = postUuid;
      payload.skus = localLittleMallSkusListState.map((sku) => (
        {
          skuCode: sku.storeSkuId,
          timestamp: sku.timestamp || [],
        }
      ))
      payload.skus = payload.skus.concat(addedSkusList);
      dispatch(updatePostSku(payload))
    }
    setIsSaveModalShow(false)
    setModalVisible(false)
  }

  const onCancelClick = () => {
    resetModal()
  }

  const onAddSkuClick = () => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    tempSku.push({
      skuCode: "",
      timestamp: [],
      timeObject: []
    })
    setAddedSkusList([...tempSku]);
  }

  const onAddedSkuCodeChange = (skuIndex: number, skuCode: string) => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    if (tempSku[skuIndex]){     
        tempSku[skuIndex].skuCode = skuCode;
    }
    setAddedSkusList([...tempSku])
  }

  const onAddedSkuDeleteClick = (skuIndex: number) => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    if (tempSku[skuIndex]){
      tempSku.splice(skuIndex ,1)
    }
    setAddedSkusList(tempSku);
  }

  const onAddedSkuTimeObjectChange = (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    if (tempSku[skuIndex] && 
        tempSku[skuIndex].timestamp && 
        tempSku[skuIndex].timestamp[timestampIndex] !== undefined &&
        tempSku[skuIndex].timeObject &&
        tempSku[skuIndex].timeObject[timestampIndex]
      ){     
        tempSku[skuIndex].timeObject[timestampIndex] = timeObject;
        if (isError){
          tempSku[skuIndex].timestamp[timestampIndex] = -1
        } else {
          tempSku[skuIndex].timestamp[timestampIndex] = convertTimeObjectToMiliSecond(timeObject)
        }
    }
    setAddedSkusList([...tempSku])
  }

  const onAddedSkuAddTimestampClick = (skuIndex: number) => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    if (tempSku[skuIndex]){
      if (tempSku[skuIndex].timestamp){
        tempSku[skuIndex].timestamp.push(0)
      } else {
        tempSku[skuIndex].timestamp = [0]
      }
      if (tempSku[skuIndex].timeObject){
        tempSku[skuIndex].timeObject.push({hour: "0", minute: "0", second: "0"})
      } else {
        tempSku[skuIndex].timeObject = [{hour: "0", minute: "0", second: "0"}]
      }
    }
    setAddedSkusList([...tempSku])
  }

  const onAddedSkuDeleteTimestampClick = (skuIndex: number, timestampIndex: number) => {
    const tempSku = JSON.parse(JSON.stringify(addedSkusList))
    if (tempSku[skuIndex] &&
      tempSku[skuIndex].timestamp &&
      tempSku[skuIndex].timeObject &&
      tempSku[skuIndex].timestamp[timestampIndex] !== undefined && 
      tempSku[skuIndex].timeObject[timestampIndex] !== undefined
    ){
        tempSku[skuIndex].timestamp.splice(timestampIndex, 1)
        tempSku[skuIndex].timeObject.splice(timestampIndex, 1)  
  } 
    setAddedSkusList([...tempSku])
  }


  const onSkuDeleteClick = (skuIndex: number) => {
    deleteSkuIndex.current = skuIndex;
    setIsDeleteSkuModalShow(true);
  }

  const onSkuDeleteConfirmClick = () => {
    const skuIndex = deleteSkuIndex.current;
    if (skuIndex !== undefined){
      if (selectedType === SkuTypeEnum.HKTV){
        const tempSku = JSON.parse(JSON.stringify(localSkuListState))
        if (tempSku[skuIndex]){
          tempSku.splice(skuIndex, 1)
        }
        setLocalSkusListState([...tempSku])
      } else {
        const tempSku = JSON.parse(JSON.stringify(localLittleMallSkusListState))
        if (tempSku[skuIndex]){
          tempSku.splice(skuIndex, 1)
        }
        setLocalLittleMallSkusListState([...tempSku])
      }
    }
    setIsDeleteSkuModalShow(false)
    deleteSkuIndex.current = undefined;
  }

  const onAddSkuTimestampClick = (skuIndex: number) => {
    if (selectedType === SkuTypeEnum.HKTV){
      const tempSku = JSON.parse(JSON.stringify(localSkuListState))
      if (tempSku[skuIndex]){
        if (tempSku[skuIndex].timestamp){
          tempSku[skuIndex].timestamp.push(0)
        } else {
          tempSku[skuIndex].timestamp = [0]
        }
        if (tempSku[skuIndex].timeObject){
          tempSku[skuIndex].timeObject.push({hour: "0", minute: "0", second: "0"})
        } else {
          tempSku[skuIndex].timeObject = [{hour: "0", minute: "0", second: "0"}]
        }
      }
      setLocalSkusListState([...tempSku])
    } else {
      const tempSku = JSON.parse(JSON.stringify(localLittleMallSkusListState))
      if (tempSku[skuIndex]){
        if (tempSku[skuIndex].timestamp){
          tempSku[skuIndex].timestamp.push(0)
        } else {
          tempSku[skuIndex].timestamp = [0]
        }
        if (tempSku[skuIndex].timeObject){
          tempSku[skuIndex].timeObject.push({hour: "0", minute: "0", second: "0"})
        } else {
          tempSku[skuIndex].timeObject = [{hour: "0", minute: "0", second: "0"}]
        }
      }
      setLocalLittleMallSkusListState([...tempSku])
    }
  }

  const onTimeObjectChange = (skuIndex: number, timestampIndex: number, timeObject: TimeObject, isError: boolean) => {
    if (selectedType === SkuTypeEnum.HKTV){
      const tempSku = JSON.parse(JSON.stringify(localSkuListState))
      if (tempSku[skuIndex] && 
        tempSku[skuIndex].timestamp && 
        tempSku[skuIndex].timestamp[timestampIndex] !== undefined &&
        tempSku[skuIndex].timeObject &&
        tempSku[skuIndex].timeObject[timestampIndex]
      ){     
        tempSku[skuIndex].timeObject[timestampIndex] = timeObject;
        if (isError){
          tempSku[skuIndex].timestamp[timestampIndex] = -1
        } else {
          tempSku[skuIndex].timestamp[timestampIndex] = convertTimeObjectToMiliSecond(timeObject)
        }
    }
      setLocalSkusListState([...tempSku])
    } else {
      const tempSku = JSON.parse(JSON.stringify(localLittleMallSkusListState))
      if (tempSku[skuIndex] && 
        tempSku[skuIndex].timestamp && 
        tempSku[skuIndex].timestamp[timestampIndex] !== undefined &&
        tempSku[skuIndex].timeObject &&
        tempSku[skuIndex].timeObject[timestampIndex]
      ){     
        tempSku[skuIndex].timeObject[timestampIndex] = timeObject;
        if (isError){
          tempSku[skuIndex].timestamp[timestampIndex] = -1
        } else {
          tempSku[skuIndex].timestamp[timestampIndex] = convertTimeObjectToMiliSecond(timeObject)
        }
    }
      setLocalLittleMallSkusListState([...tempSku])
    }
  }

  const onTimestampDeleteClick = ({skuIndex, timestampIndex}: {skuIndex: number, timestampIndex: number}) => {
    if (selectedType === SkuTypeEnum.HKTV){
      const tempSku = JSON.parse(JSON.stringify(localSkuListState))
      if (tempSku[skuIndex] &&
          tempSku[skuIndex].timestamp &&
          tempSku[skuIndex].timeObject &&
          tempSku[skuIndex].timestamp[timestampIndex] !== undefined && 
          tempSku[skuIndex].timeObject[timestampIndex] !== undefined
        ){
            tempSku[skuIndex].timestamp.splice(timestampIndex, 1)
            tempSku[skuIndex].timeObject.splice(timestampIndex, 1)          
      }
      setLocalSkusListState([...tempSku])
    } else {
      const tempSku = JSON.parse(JSON.stringify(localLittleMallSkusListState))
      if (tempSku[skuIndex] &&
        tempSku[skuIndex].timestamp &&
        tempSku[skuIndex].timeObject &&
        tempSku[skuIndex].timestamp[timestampIndex] !== undefined && 
        tempSku[skuIndex].timeObject[timestampIndex] !== undefined
      ){
          tempSku[skuIndex].timestamp.splice(timestampIndex, 1)
          tempSku[skuIndex].timeObject.splice(timestampIndex, 1)       
    }
      setLocalLittleMallSkusListState([...tempSku])
    }
  }
  

  const resetModal = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    if (postOwnerRole === "OFFICIAL" || postOwnerRole ===  "MERCHANT" || (skusList && skusList.length > 0)){
      setSelectedType(SkuTypeEnum.HKTV);
      const tempSkuList = JSON.parse(JSON.stringify(skusList))
      tempSkuList.forEach((sku : Skus) => {
        if (skuTimestampList){
          sku.timestamp = skuTimestampList.skus.find((timestamp) => (timestamp.skuCode === sku.skuCode))?.timestamp
          sku.timeObject = sku.timestamp?.map((timestamp) => ( getTimeObjectFromMiliSecond(timestamp)))
        }
      })
      setLocalSkusListState([...tempSkuList]);
    } else {
      setSelectedType(SkuTypeEnum.LITTLEMALL);
      const tempSkuList = JSON.parse(JSON.stringify(littleMallSkusList))
      tempSkuList.forEach((sku : LittleMallSkus) => {
        if (skuTimestampList){
          sku.timestamp = skuTimestampList.littleMallSkus.find((timestamp) => (timestamp.skuCode === sku.storeSkuId))?.timestamp
          sku.timeObject = sku.timestamp?.map((timestamp) => ( getTimeObjectFromMiliSecond(timestamp)))
        }
      })
      setLocalLittleMallSkusListState([...tempSkuList]);
    }
  }, [skusList, littleMallSkusList, skuTimestampList, postOwnerRole])

  return (
    <>
      <Modal
        title="Edit Post Sku"
        open={isModalVisible}
        footer={<Footer onSaveClick={onSaveClick} onCancelClick={onCancelClick} />}
        onCancel={() => resetModal()}
      >
        <div className="editSkuModal-sku-type-picker w-full flex justify-center pb-5">
          <Radio.Group 
            value={selectedType}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              setSelectedType(e.target.value)

            }}
          >
            <Radio.Button value={SkuTypeEnum.HKTV} disabled={postOwnerRole === "HKTV_SHOPS_MERCHANT"}>HKTV</Radio.Button>
            <Radio.Button value={SkuTypeEnum.LITTLEMALL} disabled={postOwnerRole === "OFFICIAL" || postOwnerRole ===  "MERCHANT"}>Little Mall</Radio.Button>
          </Radio.Group>

        </div>
        <div className="editSkuModal-wrapper">
          <div className="editSkuModal-edit-list-header flex justify-between items-center pb-5">
            <p className="font-bold self-center m-0">
              SKU List
            </p>
            <Button className="editSkuModal-add-sku-button" type="primary" onClick={onAddSkuClick}>
              Add SKU
            </Button>
          </div>
          { 
            selectedType === SkuTypeEnum.HKTV ? 
            <MallPostSkusList 
              postSkus={localSkuListState} 
              onSkuDeleteClick={onSkuDeleteClick}
              onTimeObjectChange={(skuIndex, timestampIndex, timeObject, isError) => onTimeObjectChange(skuIndex, timestampIndex, timeObject, isError)} 
              onAddTimestampClick={(skuIndex) => onAddSkuTimestampClick(skuIndex)}
              onTimestampDeleteClick={(skuIndex, timestampIndex) => onTimestampDeleteClick({skuIndex, timestampIndex})} 
            />
            : 
            <LittleMallPostSkusList 
            postSkus={localLittleMallSkusListState} 
            onSkuDeleteClick={onSkuDeleteClick}
            onTimeObjectChange={(skuIndex, timestampIndex, timeObject, isError) => onTimeObjectChange(skuIndex, timestampIndex, timeObject, isError)} 
            onAddTimestampClick={(skuIndex) => onAddSkuTimestampClick(skuIndex)}
            onTimestampDeleteClick={(skuIndex, timestampIndex) => onTimestampDeleteClick({skuIndex, timestampIndex})} 
          />
          }
          <div className="editSkuModal-add-list-header pb-5">
            <p className="font-bold self-center m-0">
              Added Sku
            </p>
          </div>
          <AddedSkuList
            addedSkus={addedSkusList}
            onSkuCodeChange={(skuIndex, skuCode) => onAddedSkuCodeChange(skuIndex, skuCode)} 
            onAddTimestampClick={(skuIndex) => onAddedSkuAddTimestampClick(skuIndex)} 
            onSkuDeleteClick={(skuIndex) => onAddedSkuDeleteClick(skuIndex)} 
            onTimeObjectChange={(skuIndex, timestampIndex, timeObject, isError) => onAddedSkuTimeObjectChange(skuIndex, timestampIndex, timeObject, isError)} 
            onTimestampDeleteClick={(skuIndex, timestampIndex) => onAddedSkuDeleteTimestampClick(skuIndex, timestampIndex)}
          />
        </div>
      </Modal>
      <ConfirmationModal 
        title={<p>Confirmation</p>}
        body={<p>Are you sure to save?</p>}
        onConfirm={() => onSaveConfirmClick()} 
        setConfirmationModalShow={setIsSaveModalShow}
        isModalVisible={isSaveModalShow}
      />
      <ConfirmationModal 
        title={<p>Confirmation</p>}
        body={<p>Are you sure to delete SKU?</p>}
        onConfirm={() => onSkuDeleteConfirmClick()} 
        setConfirmationModalShow={setIsDeleteSkuModalShow}
        isModalVisible={isDeleteSkuModalShow}
      />
    </>
  )
}

export default EditSkuModal;
