import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fail, getCensored, setCensored, updateCensored } from '@/Slices/CensorshipSlice';

import { getCensoredAPI, putCensoredAPI } from '@/Axios/api/censorship';

function* handleGetCensored() {
  try {
    const data: AxiosResponse = yield call(getCensoredAPI);
    yield put(setCensored(data.data));
  } catch (e) {
    console.log(e);
    yield put(fail(e));
  }
}

function* handlePutCensored(action: PayloadAction<string[]>) {
  try {
    const res: AxiosResponse = yield call(putCensoredAPI, action.payload);
    const updateCode = res.status;
    yield put(updateCensored({ res, updateCode }));
  } catch (e) {
    yield put(fail(e));
  }
}

export function* watchGetCensored() {
  yield takeEvery(getCensored.type, handleGetCensored);
}
export function* watchPutCensored() {
  yield takeEvery(updateCensored.type, handlePutCensored);
}
