import React from 'react';

const PostSourceLabel = ({ source }: { source: 'KOC' | 'PRP' }) => {
  const color = source === 'KOC' ? 'rgb(83, 169, 73)' : 'rgb(255, 119, 112)';
  const backgroundColor = source === 'KOC' ? 'rgba(83, 169, 73, 0.2)' : 'rgba(255, 119, 112, 0.2)';

  return (
    <div
      className="flex justify-center content-center"
      style={{
        width: '37px',
        height: '20px',
        backgroundColor,
        borderRadius: '5px',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          color,
          fontSize: '12px',
          lineHeight: '16px',
          width: '25px',
          height: '16px',
        }}
      >
        {source}
      </div>
    </div>
  );
};

export default PostSourceLabel;
