import { createSlice } from '@reduxjs/toolkit';

const WhitelistDomainSlice = createSlice({
  name: 'whitelistDomain',
  initialState: {
    values: [
      {
        domainName: '' as string,
        hostName: '' as string,
      },
    ],
    loading: false as boolean,
  },
  reducers: {
    getWhitelistDomain: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setWhitelistDomain: (state, action) => {
      const assignState = state;
      assignState.values = action.payload;
      assignState.loading = false;
    },
    postWhiteListDomain: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    postWhiteListDomainSccuss: (state) => {
      const assignState = state;
      assignState.loading = false;
    },
  },
});

export const {
  getWhitelistDomain,
  setWhitelistDomain,
  postWhiteListDomain,
  postWhiteListDomainSccuss,
} = WhitelistDomainSlice.actions;

export default WhitelistDomainSlice.reducer;
