/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import { Card } from 'antd';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getSettings } from '@/Redux/slices/SettingSlice';

import MallCategoriesSettings from '@/Components/MallCategoriesSettings';

const Category = () => {
  const dispatch = useAppDispatch();
  const { mallCategories } = useAppSelector((state) => state.setting.value);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <div className="bg-white my-6 py-4 px-6">
      <Card title="Mall categories in KOC landing page" size="small">
        <MallCategoriesSettings mallCategories={mallCategories} />
      </Card>
    </div>
  );
};

export default Category;
