import AccountManagement from '@/Pages/AccountManagement';

import PostManagementPage from '../pages/PostManagement';
import UserRoleManagement from '../pages/UserRoleManagement';

const PostManagementRoutes = [
  {
    breadcrumb: [{ path: '/account-management', breadcrumbName: 'Account Management' }],
    Component: AccountManagement,
  },
  {
    breadcrumb: [{ path: '/post-management', breadcrumbName: 'Post Management' }],
    Component: PostManagementPage,
  },
  {
    breadcrumb: [{ path: '/account-profile-tag', breadcrumbName: 'Account Profile Tag' }],
    Component: UserRoleManagement,
  },
];

export default PostManagementRoutes;
