import Affiliate from './Affiliate';
import CampaignRoutes from './Campaign';
import CensorshipRoutes from './Censorship';
import LiveShow from './LiveShow';
import PostManagement from './PostManagement';
import ReportRoutes from './Report';
import SettingRoutes from './Setting';
import VodRoutes from './Vod';
import RebateTncRoutes from './RebateTnc';
import WhitelistDomainsRoutes from './WhitelistDomains';
import CollectionRoutes from './Collection';

const mainRouters = [
  ...CensorshipRoutes,
  ...PostManagement,
  ...VodRoutes,
  ...ReportRoutes,
  ...SettingRoutes,
  ...RebateTncRoutes,
  ...LiveShow,
  ...WhitelistDomainsRoutes,
  ...Affiliate,
  ...CampaignRoutes,
  ...CollectionRoutes,
];
export default mainRouters;
