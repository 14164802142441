import {
  createPostCollection,
  fetchPostCollections,
  getCollectionPostsByUuid,
  updatePostCollection,
  createCollectionPosts as createCollectionPostsApi,
  deleteCollectionPost as deleteCollectionPostApi,
  updateCollectionPosts as updateCollectionPostsApi,
} from '@/Axios/api/postCollection';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CollectionPostsRequest, PostCollection } from '@/Interfaces/I_PostCollection';
import { message } from 'antd';
import {
  addNewCollection,
  createCollection,
  createCollectionPosts,
  deleteCollectionPost,
  getCollectionPosts,
  getCollections,
  setCollectionPosts,
  setCollections,
  setErrorMessage,
  updateCollectionPosts,
  updateCollections,
} from '../slices/PostCollectionSlice';

function* handleErrorResponse(err: Error) {
  if (axios.isAxiosError(err)) {
    yield put(setErrorMessage(err.response?.data.message));
    message.error({
      content: err.response?.data.message,
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } else {
    yield put(setErrorMessage((err as Error).message));
  }
}

function* handleGetCollections() {
  try {
    const data: AxiosResponse = yield call(fetchPostCollections);
    yield put(setCollections(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleCreateCollection(action: PayloadAction<PostCollection>) {
  try {
    const data: AxiosResponse = yield call(createPostCollection, action.payload);
    yield put(addNewCollection(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleUpdateCollections(action: PayloadAction<PostCollection[]>) {
  try {
    const data: AxiosResponse = yield call(updatePostCollection, action.payload);
    yield put(setCollections(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleGetCollectionPosts(action: PayloadAction<string>) {
  try {
    const data: AxiosResponse = yield call(getCollectionPostsByUuid, action.payload);
    yield put(setCollectionPosts(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleCreateCollectionPosts(action: PayloadAction<CollectionPostsRequest>) {
  try {
    const data: AxiosResponse = yield call(createCollectionPostsApi, action.payload);
    yield put(setCollectionPosts(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleDeleteCollectionPost(action: PayloadAction<CollectionPostsRequest>) {
  try {
    const data: AxiosResponse = yield call(deleteCollectionPostApi, action.payload);
    yield put(setCollectionPosts(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

function* handleUpdateCollectionPosts(action: PayloadAction<CollectionPostsRequest>) {
  try {
    const data: AxiosResponse = yield call(updateCollectionPostsApi, action.payload);
    yield put(setCollectionPosts(data));
  } catch (err) {
    yield call(handleErrorResponse, err as Error);
  }
}

export function* watchGetCollections() {
  yield takeEvery(getCollections.type, handleGetCollections);
}

export function* watchCreateCollection() {
  yield takeLatest(createCollection.type, handleCreateCollection);
}

export function* watchUpdateCollection() {
  yield takeEvery(updateCollections.type, handleUpdateCollections);
}

export function* watchGetCollectionPosts() {
  yield takeLatest(getCollectionPosts.type, handleGetCollectionPosts);
}

export function* watchCreateCollectionPosts() {
  yield takeEvery(createCollectionPosts.type, handleCreateCollectionPosts);
}

export function* watchDeleteCollectionPost() {
  yield takeEvery(deleteCollectionPost.type, handleDeleteCollectionPost);
}

export function* watchUpdateCollectionPosts() {
  yield takeEvery(updateCollectionPosts.type, handleUpdateCollectionPosts);
}
