import axios, { AxiosResponse } from 'axios';

import { customAxios } from '../index';

interface Response<T> {
  data: T;
  status: {
    code: string;
    message: string;
  };
}

// 取得所有審查字眼
const getCensoredAPI = (): Promise<AxiosResponse<Response<string[]>>> =>
  customAxios.get('/hktv_koc/cms/censored_keyword');
// 更改所有審查字眼
const putCensoredAPI = (data: string[]) => customAxios.put('/hktv_koc/cms/censored_keyword', data);

export { getCensoredAPI, putCensoredAPI };
