import React, { useState } from 'react';

import { Radio, RadioChangeEvent } from 'antd';

import { useAppDispatch } from '@/Hooks/useAppRedux';
import { UserRoleRadioOptionsProps } from '@/Interfaces/I_userRole';

import { putUserRole } from '@/Redux/slices/UserRoleSlice';

const UserRoleRadioOptions = ({ userPk, userRole, setUserRoleForm }: UserRoleRadioOptionsProps) => {
  const dispatch = useAppDispatch();
  const [newUserRole, setNewUserRole] = useState<string>(userRole);

  const onChange = (e: RadioChangeEvent) => {
    setNewUserRole(e.target.value);
    if (setUserRoleForm) {
      setUserRoleForm(e.target.value);
    } else {
      dispatch(
        putUserRole([
          {
            hybrisId: userPk,
            kocUserRole: e.target.value,
          },
        ]),
      );
    }
  };

  return (
    <Radio.Group onChange={onChange} value={newUserRole}>
      <Radio value="OFFICIAL">官方賬戶</Radio>
      <Radio value="CONTENT_PROVIDER">話題領袖</Radio>
      <Radio value="MERCHANT">HKTVmall商戶</Radio>
      <Radio value="HKTV_SHOPS_MERCHANT">HKTVshops商戶</Radio>
      <Radio value="YOUTUBER">LOOK創作者</Radio>
    </Radio.Group>
  );
};

export default UserRoleRadioOptions;
