import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  AccountsManagementParam,
  PatchAccountsDetailsData
} from '@/Interfaces/I_AccountsManagement';

import {
  getAccountsManagements,
  patchAccountsDetails,
  patchAccountsDetailsSuccess,
  setAccountsManagements
} from '@/Slices/AccountsManagementSlice';

import { fetchAccountsManangement, putUserDetailsApi } from '@/Axios/api/accountsManagement';

function* handleGetAccountsManagements(action: PayloadAction<AccountsManagementParam>) {
  try {
    const data: AxiosResponse = yield call(fetchAccountsManangement, action.payload);
    yield put(setAccountsManagements(data));
  } catch (e) {
    // console.error(e);
  }
}

function* handlePutAccountsDetail(action: PayloadAction<PatchAccountsDetailsData>) {
  try {
    const data: AxiosResponse = yield call(putUserDetailsApi, action.payload);
    if (data.status === 200) {
      yield put(patchAccountsDetailsSuccess(action.payload));
      message.success({
        content: 'Account status updated.',
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
    }
  } catch (e) {
    message.error({
      content: 'Fail to update account status.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* watchGetAccountsManagement() {
  yield takeLatest(getAccountsManagements.type, handleGetAccountsManagements);
}

export function* watchPatchAccoutsDetail() {
  yield takeEvery(patchAccountsDetails.type, handlePutAccountsDetail);
}
