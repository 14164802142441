interface TimeObject {
  hour: string | undefined,
  minute: string | undefined,
  second: string | undefined,
}

export const timeTranslate = (timeString: number, detail = false) => {
  let timeStamp = timeString;
  if (timeString?.toString().length === 10) {
    timeStamp = timeString * 1000;
  }
  const DD = new Date(timeStamp).getDate().toString().padStart(2, '0');
  const MM = (new Date(timeStamp).getMonth() + 1).toString().padStart(2, '0');
  const YYYY = new Date(timeStamp).getFullYear();
  const HH = new Date(timeStamp).getHours().toString().padStart(2, '0');
  const MIN = new Date(timeStamp).getMinutes().toString().padStart(2, '0');
  if (detail) return `${YYYY}-${MM}-${DD} ${HH}:${MIN}`;
  return `${YYYY}-${MM}-${DD}`;
};

export const getTimeObjectFromMiliSecond = (miliSec: number): TimeObject => {
  const hour = Math.floor(miliSec / 3600000);
  const minute = Math.floor((miliSec - hour * 3600000) / 60000);
  const second = Math.floor((miliSec- hour * 3600000 - minute* 60000) / 1000);
  const hourString = hour.toString();
  const minuteString = minute.toString();
  const secondString = second.toString()

  return {
    hour: hourString,
    minute: minuteString,
    second: secondString,
  }
}

export const convertTimeObjectToMiliSecond = (timeObject: TimeObject): number => {
  if (timeObject.hour !== undefined && timeObject.minute !== undefined && timeObject.second !== undefined){
    return Number(timeObject.hour)*3600000 + Number(timeObject.minute)*60000 + Number(timeObject.second)*1000
  }
  return -1
}
 


