/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Popconfirm, Select, SelectProps, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import {
  getCategories,
  getPinnedExplorePosts,
  updatePinnedExplorePosts,
} from '@/Redux/slices/PostManagementSlice';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { timeTranslate } from '@/Utils/timeTranslate';
import UserRoleLabel from '@/Modules/userRoleLabel';
import { UserRoleEnums } from '@/Interfaces/I_userRole';
import Modal from '@/Modules/modal';
import { getPostDetail } from '@/Redux/slices/ModalSlice';
import { getFrontEndDomainByEnv } from '@/Axios/index';
import fileText from '../../images/icon/fileText.svg';

interface PinnedExplorePostUI {
  priority: number;
  post: {
    uuid: string;
    title: string;
    creationDate: number;
  };
  user: {
    pkey: string;
    name: string;
    role: string | undefined;
  };
}

// reference: https://ant.design/components/table#components-table-demo-drag-sorting-handler
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ExplorePage = ({ tabActive }: { tabActive: boolean }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.postManagement.loading);
  const userName = useAppSelector((state) => state.account.user.username);
  const { categories, pinnedExplorePosts } = useAppSelector((state) => state.postManagement.value);

  const [categoryOptions, setCategoryOptions] = useState([] as SelectProps[]);
  const [pinnedExplorePostUIs, setPinnedExplorePostUIs] = useState([] as PinnedExplorePostUI[]);
  const [pristine, setPristine] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);

  const selectedCategory = useRef<string>('');

  const initPinnedExplorePostUIs = useCallback(() => {
    const pinnedLandingData = pinnedExplorePosts.map((post) => ({
      priority: post.priority,
      title: post.title,
      uuid: post.uuid,
      post: {
        uuid: post.uuid,
        title: post.title,
        creationDate: post.creationDate,
      },
      user: {
        pkey: post.user.hybrisId,
        name: post.user.name,
        role: post.user.kocUserRole,
      },
    }));
    setPinnedExplorePostUIs(pinnedLandingData);
  }, [pinnedExplorePosts]);

  // fetch categories
  useEffect(() => {
    if (userName) {
      dispatch(getCategories());
    }
  }, [dispatch, userName]);

  // map categories to SelectProps type
  useEffect(() => {
    if (!loading && categories.length > 0) {
      const options = categories.map((c) => ({ value: c.categoryId, label: c.name }));
      setCategoryOptions(options);
    }
  }, [loading, categories]);

  // fetch pinned explore post of selected category
  useEffect(() => {
    if (selectedCategory.current && tabActive) {
      dispatch(getPinnedExplorePosts(selectedCategory.current));
    } else if (categories.length > 0 && tabActive) {
      dispatch(getPinnedExplorePosts(categories[0].categoryId));
      selectedCategory.current = categories[0].categoryId;
    }
  }, [dispatch, categories, tabActive]);

  // map pinned explore post to table row type
  useEffect(() => {
    if (!loading && pinnedExplorePosts) {
      initPinnedExplorePostUIs();
    }
  }, [loading, pinnedExplorePosts, initPinnedExplorePostUIs]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setPinnedExplorePostUIs((previous) => {
        const activeIndex = previous.findIndex((i) => i.post.uuid === active.id);
        const overIndex = previous.findIndex((i) => i.post.uuid === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      setPristine(false);
    }
  };

  const removePinnedLanding = (postUuid: string) => {
    setPinnedExplorePostUIs((previous) => previous.filter((ui) => ui.post.uuid !== postUuid));
    setPristine(false);
  };

  const onUpdatePinnedLandings = () => {
    dispatch(
      updatePinnedExplorePosts({
        categoryCode: selectedCategory.current,
        cmsPinnedPostUuids: pinnedExplorePostUIs.map((ui) => ui.post.uuid),
      }),
    );
    setPristine(true);
  };

  const openPostDetailModal = (postUuid: string) => {
    setModalVisible(true);
    dispatch(getPostDetail(postUuid));
  };

  const columns: ColumnsType<PinnedExplorePostUI> = [
    {
      key: 'sort',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
    },
    {
      key: 'title',
      title: 'Post Title',
      dataIndex: 'post',
      render: (post: { uuid: string; title: string; creationDate: number }) => (
        <Tooltip placement="leftTop" title={post.title}>
          <div className="flex items-center">
            <a 
              className="line-clamp-3 break-all mb-0" 
              href= {`${getFrontEndDomainByEnv()}post/${post.uuid}`}
              target="_blank"
              rel="noreferrer"
            >{post.title}</a>
            <img
              aria-hidden
              src={fileText}
              alt="photo"
              className="cursor-pointer ml-2"
              onClick={() => openPostDetailModal(post.uuid)}
            />
          </div>
        </Tooltip>
      ),
      width: '20%',
    },
    {
      title: 'Post Uuid',
      dataIndex: ['post', 'uuid'],
      render: (uuid: string) => (
        <Tooltip placement="leftTop" title={uuid}>
          {uuid}
        </Tooltip>
      ),
      width: '20%',
    },
    {
      title: 'Post Created',
      dataIndex: ['post', 'creationDate'],
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
    },
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      render: (user: string) => (
        <Tooltip placement="leftTop" title={user}>
          <p className="h-6 line-clamp-1 break-all m-0">{user}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Pkey',
      dataIndex: ['user', 'pkey'],
    },
    {
      title: 'User Role',
      dataIndex: ['user', 'role'],
      render: (role: string) => <UserRoleLabel userRoleStr={role as UserRoleEnums} />,
    },
    {
      key: 'remove',
      title: 'Delete',
      dataIndex: ['post', 'uuid'],
      render: (uuid: string) => (
        <Popconfirm
          title="Confirm?"
          onConfirm={() => removePinnedLanding(uuid)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div className="h-auto bg-white p-6 pb-2">
        <div className="flex items-center pb-4 justify-between">
          <div className="flex">
            {categoryOptions.length > 0 && (
              <Select
                style={{ width: 120 }}
                options={categoryOptions}
                onChange={(value) => {
                  selectedCategory.current = value;
                  dispatch(getPinnedExplorePosts(value));
                  setPristine(true);
                }}
                defaultValue={categoryOptions[0].value}
              />
            )}
          </div>
          <div className="flex">
            <div className="pr-2">
              <Button type="primary" onClick={onUpdatePinnedLandings} disabled={pristine}>
                Update
              </Button>
            </div>
            <div>
              <Button type="primary" onClick={initPinnedExplorePostUIs} disabled={pristine}>
                Reset
              </Button>
            </div>
          </div>
        </div>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={pinnedExplorePostUIs.map((i) => i.post.uuid)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="uuid"
              columns={columns}
              dataSource={pinnedExplorePostUIs}
              pagination={false}
              loading={loading}
            />
          </SortableContext>
        </DndContext>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        modalType="postDetail"
      />
    </>
  );
};

export default ExplorePage;
