import React from 'react';

import Tab from '@/Modules/tab/Tab';

import HidLog from './HidLog';
import IgnoredLog from './IgnoredLog';
import Outstanding from './Outstanding';

const ReportPage = () => (
  <Tab
    defaultActiveKey="1"
    initialPanes={[
      {
        title: 'Outstanding',
        content: <Outstanding />,
        key: '1',
      },
      { title: 'Hid Log', content: <HidLog />, key: '2' },
      { title: 'Ignored Log', content: <IgnoredLog />, key: '3' },
    ]}
  />
);

export default ReportPage;
