import { DndEditableTableData } from '@/Components/dnd/DndEditableTable';
import { FormInstance } from 'antd/es/form/Form';
import { useForm } from 'antd/lib/form/Form';
import { useMemo, useState } from 'react';

export type DndFormInstance<T extends DndEditableTableData> = {
  form: FormInstance;
  // isPristine will be false after drag and drop or edting row data in DndEditableTable
  isPristine: boolean;
  // the initial value passed to useDndForm()
  initialValue: T[];
  // updated value after drag and drop or editing row data
  currentValue: T[];
  updateCurrentValue: (value: T[]) => void;
  setIsPristine: (value: boolean) => void;
};

const useDndForm = <T extends DndEditableTableData>(value: T[]): DndFormInstance<T> => {
  const [form] = useForm();
  const [currentValue, setCurrentValue] = useState<T[]>(value);
  const [isPristine, setIsPristine] = useState(true);

  const initialValue = useMemo(() => value, [value]);

  const updateCurrentValue = (newValue: T[]) => {
    setCurrentValue(newValue);
    setIsPristine(false);
  };

  return {
    form,
    isPristine,
    initialValue,
    currentValue,
    updateCurrentValue,
    setIsPristine,
  };
};

export default useDndForm;
