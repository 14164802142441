import { FetchUserRoleParam, PutUserRoleParam } from '@/Interfaces/I_userRole';

import { customAxios } from '../index';

// get user role
export const fetchUserRoleApi = (data: FetchUserRoleParam) =>
  customAxios({
    url: 'hktv_koc/cms/accounts/role',
    method: 'GET',
    params: data,
  });

export const putUserRoleApi = (data: PutUserRoleParam[]) =>
  customAxios({
    url: 'hktv_koc/cms/accounts/role',
    method: 'PUT',
    data,
  });

export default fetchUserRoleApi;
