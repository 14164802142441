import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { reportPageData } from '@/Interfaces/I_report';

export const reportSlice = createSlice({
  name: 'postManagementSlice',
  initialState: {
    value: {
      outstanding: {} as reportPageData,
      hidLog: {} as reportPageData,
      ignoredLog: {} as reportPageData,
    },
    loading: false,
    error: '',
  },
  reducers: {
    getReportPost: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setOutstandingPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, outstanding: action.payload.data };
      assignState.loading = false;
    },
    setHidLogPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, hidLog: action.payload.data };
      assignState.loading = false;
    },
    setIgnoredLogPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, ignoredLog: action.payload.data };
      assignState.loading = false;
    },
  },
});

export const { getReportPost, setOutstandingPost, setHidLogPost, setIgnoredLogPost } =
  reportSlice.actions;

export default reportSlice.reducer;
