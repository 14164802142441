import React from 'react';

import Tab from '@/Modules/tab/Tab';

import UserRole from './UserRole';

const UserRoleManagement = () => (
  <Tab
    defaultActiveKey="1"
    initialPanes={[
      {
        title: 'User Account',
        content: <UserRole />,
        key: '1',
      },
    ]}
  />
);

export default UserRoleManagement;
