import { AffiliateBudgetParam, AffiliateBudgetPostData } from '@/Interfaces/I_Affiliate';
import { customAxios } from '../index';

const getAffiliateBudgetDataAPI = (data: AffiliateBudgetParam) =>
  customAxios({
    url: 'hktv_koc/cms/budget_policies/list',
    method: 'GET',
    params: data,
  });

const postAffiliateBudgetAPI = (data: AffiliateBudgetPostData) =>
  customAxios.post('/hktv_koc/cms/budget_policies/create', data);

const patchAffiliateBudgetRecalculateAPI = (id: number) =>
  customAxios({
    url: `hktv_koc/cms/budget_policies/${id}/spent`,
    method: 'PATCH',
  });

export { getAffiliateBudgetDataAPI, postAffiliateBudgetAPI, patchAffiliateBudgetRecalculateAPI };
