/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Image, Upload } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload';
import { DndEditableTableCellProps } from './DndEditableTableCell';

const DndEditableTableImageCell = ({
  dataIndex,
  editable,
  editing,
  value,
  uploadImageProps,
  ...props
}: DndEditableTableCellProps) => {
  const form = Form.useFormInstance();
  const [files, setFiles] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'original image',
      status: 'done',
      url: value,
    },
  ]);

  const onFileChange = (info: UploadChangeParam<UploadFile>) => {
    setFiles([...info.fileList].slice(-1));
    if (info.file.status === 'done') {
      if (uploadImageProps?.onUploadSuccess) {
        uploadImageProps.onUploadSuccess(info.file);
      }
    }
  };

  return (
    <td {...props}>
      {editable && editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Upload
            {...uploadImageProps}
            onRemove={() => form.setFieldValue(dataIndex, '')}
            onChange={onFileChange}
            fileList={files}
          >
            <Button icon={<EditOutlined />}>Edit</Button>
          </Upload>
        </Form.Item>
      ) : (
        <Image width={100} height={100} src={value} preview={false} />
      )}
    </td>
  );
};

export default DndEditableTableImageCell;
