import Campaign from '@/Pages/Campaign';

const CampaignRoutes = [
  {
    breadcrumb: [{ path: '/campaign', breadcrumbName: 'Campaign' }],
    Component: Campaign,
  },
];

export default CampaignRoutes;
