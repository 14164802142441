import Login from '../pages/Account/Login';

const LoginRoutes = [
  {
    breadcrumb: [{ path: '/login', breadcrumbName: 'Login' }],
    needAuth: false,
    Component: Login,
  },
];

export default LoginRoutes;
