import { AffiliateBudgetData } from '@/Interfaces/I_Affiliate';
import { PaginationData } from '@/Interfaces/I_postManagement';
import { createSlice } from '@reduxjs/toolkit';

const AffiliateSlice = createSlice({
  name: 'affiliate',
  initialState: {
    value: {
      kocBudgetData: {
        data: [] as AffiliateBudgetData[],
        pagination: {} as PaginationData,
      },
      vodBudgetData: {
        data: [] as AffiliateBudgetData[],
        pagination: {} as PaginationData,
      },
    },
    loading: false as boolean,
    error: '' as string,
  },
  reducers: {
    getAffiliateBudgetData: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setAffiliateKocBudgetData: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, kocBudgetData: action.payload.data.data };
      assignState.loading = false;
    },
    setAffiliateVodBudgetData: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, vodBudgetData: action.payload.data.data };
      assignState.loading = false;
    },
    postAffiliateBudget: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    postAffiliateKocBudgetSuccess: (state, action) => {
      const assignState = state;
      assignState.value.kocBudgetData.data = [
        ...assignState.value.kocBudgetData.data,
        action.payload,
      ];
      assignState.loading = false;
    },
    postAffiliateVodBudgetSuccess: (state, action) => {
      const assignState = state;
      assignState.value.vodBudgetData.data = [
        ...assignState.value.vodBudgetData.data,
        action.payload,
      ];
      assignState.loading = false;
    },
    patchAffiliateBudgetRecalculate: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    patchAffiliateBudgetRecalculateSuccess: (state, action) => {
      const { id } = action.payload;
      let patchDataIndex = [...state.value.kocBudgetData.data].findIndex((e) => e.id === id);
      if (patchDataIndex !== -1) {
        const assignState = state;
        assignState.value.kocBudgetData.data[patchDataIndex] = { ...action.payload };
        assignState.loading = false;
      } else {
        patchDataIndex = [...state.value.vodBudgetData.data].findIndex((e) => e.id === id);
        const assignState = state;
        assignState.value.vodBudgetData.data[patchDataIndex] = { ...action.payload };
        assignState.loading = false;
      }
    },
  },
});

export const {
  getAffiliateBudgetData,
  setAffiliateKocBudgetData,
  setAffiliateVodBudgetData,
  postAffiliateBudget,
  postAffiliateKocBudgetSuccess,
  postAffiliateVodBudgetSuccess,
  patchAffiliateBudgetRecalculate,
  patchAffiliateBudgetRecalculateSuccess,
} = AffiliateSlice.actions;

export default AffiliateSlice.reducer;
