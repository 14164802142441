interface ChannelData {
  channelId: number;
  channelName: string;
  mallCategoryId?: number;
  mallCategoryCode?: string;
  mallCategoryName?: string;
  priority: number;
  disabled: boolean;
}

interface ChannelCategoryData {
  categoryId: string;
  categoryName: string;
  imageUrl: string;
  selected: boolean;
}

interface ChannelPostDataParam {
  channelId: number;
  postUuid: string[];
}

interface CreateChannelRequest {
  channelName: string;
  disabled: boolean;
  categoryIds: string[];
  mallCategoryId: string;
}

interface UpdateChannelRequest {
  channels: ChannelData[];
}

export const VIDEO_PLAYER = 'videoPlayer';

export type {
  ChannelData,
  ChannelCategoryData,
  ChannelPostDataParam,
  CreateChannelRequest,
  UpdateChannelRequest,
};
