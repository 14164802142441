import {
  ChannelPostDataParam,
  CreateChannelRequest,
  UpdateChannelRequest,
} from '@/Interfaces/I_vod';
import { customAxios } from '../index';

// get channel
const fetchAllChannelAPI = () => customAxios.get('hktv_koc/cms/channels');

// get channel categories
const fetchChannelCategoryAPI = (channelId: number) =>
  customAxios.get(`hktv_koc/cms/channels/categories/${channelId}`);

// add post to channel
const postChannelPostAPI = ({ channelId, ...data }: ChannelPostDataParam) =>
  customAxios.post(`hktv_koc/cms/channels/${channelId}`, data);

// delete post in channel
const deleteChannelPostAPI = ({ channelId, ...data }: ChannelPostDataParam) =>
  customAxios.delete(`hktv_koc/cms/channels/${channelId}`, { data });

// create channel
const createChannelAPI = (data: CreateChannelRequest) =>
  customAxios.post('hktv_koc/cms/channels/create', data);

const updateChannelAPI = (data: UpdateChannelRequest) =>
  customAxios.post('hktv_koc/cms/channels/update', data);

export {
  fetchAllChannelAPI,
  fetchChannelCategoryAPI,
  postChannelPostAPI,
  deleteChannelPostAPI,
  createChannelAPI,
  updateChannelAPI,
};
