import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { CampaignParam, CampaignUiData } from '@/Interfaces/I_Campaign';

import {
  getCampaignDataApi,
  patchCampaignDataApi,
  postCampaignDataApi
} from '@/Axios/api/campaign';

import {
  getCampaignData,
  patchCampaignData,
  patchCampaignDataSuccess,
  postCampaignData,
  postCampaignDataSuccess,
  setCampaignData
} from '../slices/CampaignSlice';

function* handleGetCampaignData(action: PayloadAction<CampaignParam>) {
  try {
    const data: AxiosResponse = yield call(getCampaignDataApi, action.payload);
    yield put(setCampaignData(data.data));
  } catch (e) {
    message.error({
      content: 'Fail to get campaign.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePostCampaignData(action: PayloadAction<CampaignUiData>) {
  try {
    const data: AxiosResponse = yield call(postCampaignDataApi, action.payload);
    yield put(postCampaignDataSuccess(data.data));
    message.success({
      content: 'New campaign details is created.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to create new campaign.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePatchCampaignData(action: PayloadAction<CampaignUiData>) {
  try {
    const data: AxiosResponse = yield call(patchCampaignDataApi, action.payload);
    yield put(patchCampaignDataSuccess(data.data));
    message.success({
      content: 'Campaign details updated.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  } catch (e) {
    message.error({
      content: 'Fail to update campaign.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetCampaignData() {
  yield takeLatest(getCampaignData.type, handleGetCampaignData);
}

export function* watchPostCampaignData() {
  yield takeEvery(postCampaignData.type, handlePostCampaignData);
}

export function* watchPatchCampaignData() {
  yield takeLatest(patchCampaignData.type, handlePatchCampaignData);
}
