import React, { useEffect, useRef, useState } from 'react';

import { Spin } from 'antd';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getUserReportRecord } from '@/Redux/slices/ModalSlice';

import Table from '@/Modules/table';
import TagLabel from '@/Modules/tagLabel';

import lazyLoad from '@/Utils/lazyLoad';
import { timeTranslate } from '@/Utils/timeTranslate';

interface UserReportRecordProps {
  userRecordData?: {
    key: number;
    handlingTime: string;
    admin: string;
    action: string;
    reason: string;
  }[];
  pending?: boolean;
}

const UserReportRecord = ({
  userRecordData = [
    {
      key: 1,
      handlingTime: 'YYYY-MM-DD 00:00',
      admin: 'Chan Tai Ming',
      action: 'DISABLE',
      reason: 'Sex, violence ',
    },
  ],
  pending = false,
}: UserReportRecordProps) => {
  const userRecordColumns = [
    {
      title: 'Handling Time',
      dataIndex: 'handlingTime',
      key: 'handlingTime',
      width: '19.18%',
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
      ellipsis: true,
      width: '22.28%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      className: 'pr-0',
      render: (text: any) =>
        text !== '' && (
          <div className="flex justify-start p-0">
            <TagLabel labelType="status" statusType={text} />
          </div>
        ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      className: 'pr-0',
    },
  ];
  const userColumns = [
    {
      title: 'Reported Time',
      dataIndex: 'reportedTime',
      key: 'reportedTime',
      width: '19.18%',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      ellipsis: true,
      width: '22.28%',
    },
    {
      title: 'Membership',
      dataIndex: 'membership',
      key: 'membership',
      width: 107,
      className: 'pr-0',
      render: (text: string) => (
        <TagLabel
          labelType="level"
          levelType={text.toLowerCase() as 'normal' | 'vip' | 'goldvip'}
        />
      ),
    },
    {
      title: 'PKey',
      dataIndex: 'PKey',
      key: 'PKey',
      width: 103,
    },
    {
      title: 'Report Reason',
      dataIndex: 'reportReason',
      key: 'reportReason',
    },
  ];

  const dispatch = useAppDispatch();
  const [delay, setDelay] = useState(false);
  const mainRef = useRef<HTMLParagraphElement | null>(null);
  const userAPIData = useAppSelector((state) => state.modal.value.userReportRecord.data);
  const pagination = useAppSelector((state) => state.modal.value.userReportRecord.pagination);
  const loading = useAppSelector((state) => state.modal.loading);

  useEffect(() => {
    if (!loading && pagination.page === 0) {
      mainRef.current?.scrollTo(0, 0);
    }
  }, [loading, pagination]);

  useEffect(() => {
    const timer = setTimeout(() => setDelay(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return !loading && userAPIData ? (
    <div
      className="p-6 h-c-492 overflow-y-scroll"
      ref={mainRef}
      onScroll={() => {
        lazyLoad({
          height: 0.8,
          ref: mainRef,
          delay,
          setDelay,
          loadMoreCallback: () => {
            if (pagination.hasNext) {
              if (pending) {
                dispatch(
                  getUserReportRecord({
                    post_uuid: userRecordData[0].key,
                    page_no: pagination.page + 1,
                    append: true,
                  }),
                );
              } else {
                dispatch(
                  getUserReportRecord({
                    post_report_handle_id: userRecordData[0].key,
                    page_no: pagination.page + 1,
                    append: true,
                  }),
                );
              }
            }
          },
        });
      }}
    >
      <Table columns={userRecordColumns} dataSource={userRecordData} pagination={false} />
      <Table
        className="mt-2.5"
        columns={userColumns}
        dataSource={userAPIData.map((item) => ({
          key: item.id,
          reportedTime: timeTranslate(item.creationDate, true),
          user: item.user.name,
          membership: item.user.membershipLevel,
          PKey: item.user.hybrisId,
          reportReason: item.reasons.split('/').join(', '),
        }))}
        pagination={false}
      />
    </div>
  ) : (
    <div className="h-c-492 flex justify-center items-center">
      <Spin size="large" />
    </div>
  );
};

export default UserReportRecord;
