import React from 'react';
import './pageTitle.scss';

interface TitleProps {
  /**
   * page title
   */
  title?: string;
}

const PageTitle = ({ title }: TitleProps) => <h1 className="storybook-pageHeader">{title}</h1>;
export default PageTitle;
export type { TitleProps };
