import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ValueProps {
  tnc: string;
  updateCode: number
  message: string
}
const rebateTncSlice = createSlice({
  name: 'rebateTnc',
  initialState: {
    value: {
      tnc: '',
      updateCode: 0,
    } as ValueProps,
    loading: false,
    error: '',
  },
  reducers: {
    getTnc: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updateTnc: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    refreshTnc: (state, action) => {
      const assignState = state;
      assignState.value = { tnc: action.payload.data, updateCode: action.payload.status, message: action.payload.statusText };
      assignState.loading = true;
      assignState.error = '';
    },
    setTnc: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, tnc: action.payload };
      assignState.loading = false;
    }
  }
});

export const { getTnc, updateTnc, setTnc, refreshTnc} = rebateTncSlice.actions;

export default rebateTncSlice.reducer;