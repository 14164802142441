import React from 'react';

import { Tag, TagProps } from 'antd';

interface UserRoleLabelProps extends TagProps {
  userRoleStr:
    | 'CUSTOMER'
    | 'OFFICIAL'
    | 'CONTENT_PROVIDER'
    | 'MERCHANT'
    | 'HKTV_SHOPS_MERCHANT'
    | 'YOUTUBER';

  onClick?: () => void;
}

const UserRoleLabel = ({ userRoleStr = 'CUSTOMER', onClick, ...props }: UserRoleLabelProps) => {
  let labelText = '';
  let color = '';
  if (userRoleStr === 'OFFICIAL') {
    labelText = '官方帳戶';
    color = 'cyan';
  }
  if (userRoleStr === 'CONTENT_PROVIDER') {
    labelText = '話題領袖';
    color = 'pink';
  }
  if (userRoleStr === 'MERCHANT') {
    labelText = 'HKTVmall商戶';
    color = 'green';
  }
  if (userRoleStr === 'HKTV_SHOPS_MERCHANT') {
    labelText = 'HKTVshops商戶';
    color = 'purple';
  }
  if (userRoleStr === 'YOUTUBER') {
    labelText = 'LOOK創作者';
    color = 'purple';
  }
  return (
    <Tag color={color} onClick={onClick}>
      {labelText || userRoleStr}
    </Tag>
  );
};
export default UserRoleLabel;
