import { all, fork } from 'redux-saga/effects';

import * as accountSagas from '@/Sagas/AccountSaga';
import * as accountsManagementSaga from '@/Sagas/AccountsManagementSaga';
import * as CensorshipSagas from '@/Sagas/CensorshipSaga';
import * as modalSaga from '@/Sagas/ModalSaga';
import * as liveShowSaga from '@/Redux/sagas/LiveShowSaga';
import * as postManagementSagas from '@/Sagas/PostManagementSaga';
import * as reportSagas from '@/Sagas/ReportSaga';
import * as settingSagas from '@/Sagas/SettingSaga';
import * as userRoleSagas from '@/Sagas/UserRoleSaga';
import * as whitelistSagas from '@/Sagas/WhitelistDomainSaga';
import * as affiliateSagas from '@/Sagas/AffiliateSaga';
import * as compaignSagas from '@/Sagas/CampaignSaga';
import * as vodSagas from '@/Sagas/VodSaga';
import * as rebateTncSagas from '@/Redux/sagas/RebateTncSaga';
import * as postCollectionSagas from '@/Redux/sagas/PostCollectionSaga';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(accountsManagementSaga),
      ...Object.values(accountSagas),
      ...Object.values(affiliateSagas),
      ...Object.values(compaignSagas),
      ...Object.values(liveShowSaga),
      ...Object.values(vodSagas),
      ...Object.values(postManagementSagas),
      ...Object.values(modalSaga),
      ...Object.values(reportSagas),
      ...Object.values(rebateTncSagas),
      ...Object.values(CensorshipSagas),
      ...Object.values(settingSagas),
      ...Object.values(userRoleSagas),
      ...Object.values(whitelistSagas),
      ...Object.values(postCollectionSagas),
    ].map(fork),
  );
}
