import {
  AccountsManagementParam,
  PatchAccountsDetailsData
} from '@/Interfaces/I_AccountsManagement';

import { customAxios } from '../index';

export const fetchAccountsManangement = (data: AccountsManagementParam) =>
  customAxios({
    url: 'hktv_koc/cms/accounts',
    method: 'GET',
    params: data,
  });

export const putUserDetailsApi = ({ userUuid, ...data }: PatchAccountsDetailsData) =>
  customAxios({
    url: `hktv_koc/cms/accounts/${userUuid}`,
    method: 'PATCH',
    data,
  });
