import CryptoJS from 'crypto-js';

const SECRET_KEY = 'koccms';

// 記住密碼(加密)
export const storedCodeEncrypt = (plainCode: string) => {
  const srcs = CryptoJS.enc.Utf8.parse(plainCode);
  const encrypted = CryptoJS.AES.encrypt(srcs, SECRET_KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

// 記住密碼(解密)
export const storedCodeDecrypt = (cipherCode: string) => {
  const dncrypted = CryptoJS.AES.decrypt(cipherCode, SECRET_KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(dncrypted);
};
