/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  DashboardOutlined,
  EditOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  FormOutlined,
  MoneyCollectOutlined,
  PlaySquareOutlined,
  SettingOutlined,
  VideoCameraAddOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { logoutStart } from '@/Slices/AccountSlice';

import Menu from '@/Modules/menu';
import PageHeader from '@/Modules/pageHeader';

// import { AccessControl, isAllowed } from '@/Components/RoleBasedControl';

const { Content } = Layout;

interface IProps {
  children: React.ReactNode;
}

const LayoutComponent: React.FC<IProps> = ({ children }: IProps) => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector((state) => state.account.user);

  // const accessControl: Array<AccessControl> = useAppSelector(
  //   (state) => state.account.accessControl,
  // );

  const navBarItem = [
    {
      itemPath: '/dashboard',
      itemLabel: 'Dashboard',
      itemIcon: (style: React.CSSProperties) => <DashboardOutlined style={style} />,
    },
    {
      itemPath: '/report',
      itemLabel: 'Report',
      itemIcon: (style: React.CSSProperties) => <WarningOutlined style={style} />,
    },
    {
      itemPath: '/management',
      itemLabel: 'Management',
      itemIcon: (style: React.CSSProperties) => <FormOutlined style={style} />,
      subMenuItem: [
        {
          key: 'account-management',
          itemPath: '/account-management',
          itemLabel: 'Account',
        },
        {
          key: 'account-profile-tag',
          itemPath: '/account-profile-tag',
          itemLabel: 'Account Profile Tag',
        },
        {
          key: 'post-management',
          itemPath: '/post-management',
          itemLabel: 'Post',
        },
      ],
    },
    {
      itemPath: '/vod',
      itemLabel: 'VOD Management',
      itemIcon: (style: React.CSSProperties) => <PlaySquareOutlined style={style} />,
    },
    {
      itemPath: '/live-show',
      itemLabel: 'Live Show',
      itemIcon: (style: React.CSSProperties) => <VideoCameraAddOutlined style={style} />,
    },
    {
      itemPath: '/censorship',
      itemLabel: 'Censorship',
      itemIcon: (style: React.CSSProperties) => <CheckCircleOutlined style={style} />,
    },
    {
      itemPath: '/whitelist-domains',
      itemLabel: 'Whitelist Domains',
      itemIcon: (style: React.CSSProperties) => <FileDoneOutlined style={style} />,
    },
    {
      itemPath: '/affiliate',
      itemLabel: 'Affiliate',
      itemIcon: (style: React.CSSProperties) => <MoneyCollectOutlined style={style} />,
    },
    {
      itemPath: '/campaign',
      itemLabel: 'Campaign',
      itemIcon: (style: React.CSSProperties) => <CalendarOutlined style={style} />,
    },

    {
      itemPath: '/setting/category',
      itemLabel: 'Setting',
      itemIcon: (style: React.CSSProperties) => <SettingOutlined style={style} />,
    },
    {
      itemPath: '/setting/rebate-tnc',
      itemLabel: 'Rebate TNC',
      itemIcon: (style: React.CSSProperties) => <EditOutlined style={style} />,
    },
    {
      itemPath: '/collections',
      itemLabel: 'Collections',
      itemIcon: (style: React.CSSProperties) => <FolderOpenOutlined style={style} />,
    },
  ];

  const [navbarClose, setNavbar] = useState(!(window.innerWidth > 1440));

  const handleMenu = () => {
    if (window.innerWidth > 1440) setNavbar(false);
    else setNavbar(true);
  };

  const handleLogout = () => {
    const token: string = Cookies.get('accessToken') || '';
    dispatch(logoutStart({ token }));
  };

  useEffect(() => {
    window.addEventListener('resize', handleMenu);
    return () => {
      window.removeEventListener('resize', handleMenu);
    };
  }, []);

  return (
    <Layout className="min-h-full relative">
      <div className="fixed top-0 left-0 w-full z-50">
        <PageHeader
          username={username}
          onLogoClick={() => navigate('/')}
          onUserClick={() => {}}
          onLogoutClick={() => handleLogout()}
        />
      </div>
      <Layout className="flex flex-row bg-bg">
        <div className={`fixed top-12 left-0 h-full z-40 `}>
          <Menu
            closeMenu={navbarClose}
            menuItem={navBarItem}
            defaultSelectedPath="/report"
            onCloseButtonClick={() => setNavbar(!navbarClose)}
          />
        </div>
        <Layout className="bg-bg">
          <Content
            className={`relative min-h-c-full-header mt-12 pt-24 px-6 ${
              !navbarClose ? 'ml-52' : 'ml-c-47'
            }`}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
