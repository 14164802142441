import { uploadPostCollectionImageUrl } from '@/Axios/api/Media';
import { customAxios } from '@/Axios/index';
import CreateCollectionModal from '@/Components/CreateCollectionModal';
import DndEditableTable, {
  DndEditableTableColumnProps,
  DndEditableTableData,
} from '@/Components/dnd/DndEditableTable';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import useDndForm from '@/Hooks/useDndForm';
import { PostCollection } from '@/Interfaces/I_PostCollection';
import { getCollections, updateCollections } from '@/Redux/slices/PostCollectionSlice';
import { Button } from 'antd';
import { UploadFile } from 'antd/es/upload';
import React, { useEffect, useMemo, useState } from 'react';

type CollectionRecord = PostCollection & DndEditableTableData;

const uploadImageUrl = customAxios.defaults.baseURL + uploadPostCollectionImageUrl;
const headers = {
  authorization: customAxios.defaults.headers.common.Authorization,
};

const CollectionList = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.postCollection.loading);
  const collections = useAppSelector((state) => state.postCollection.value.collections);

  const [isModalVisible, setModalVisible] = useState(false);

  const records: CollectionRecord[] = useMemo(
    () =>
      (collections || [])
        .map((c) => ({
          key: c.priority.toString(),
          ...c,
        }))
        .sort((c1, c2) => c1.priority - c2.priority),
    [collections],
  );

  useEffect(() => {
    if (active) {
      dispatch(getCollections());
    }
  }, [active, dispatch]);

  const dndForm = useDndForm(records);

  const onSave = () => {
    const updated: PostCollection[] = dndForm.currentValue.map((c, index) => ({
      ...c,
      priority: index + 1,
    }));

    dispatch(updateCollections(updated));
  };

  const columns: DndEditableTableColumnProps<CollectionRecord>[] = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      editable: false,
      width: 50,
    },
    {
      title: 'Collection Link',
      dataIndex: 'uuid',
      editable: false,
      width: '20%',
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      align: 'center',
      inputType: 'image',
      width: '20%',
      uploadImageProps: {
        action: uploadImageUrl,
        headers,
        name: 'images',
        onUploadSuccess: (file: UploadFile) => {
          dndForm.form.setFieldValue('imageUrl', file.response[0].imageUrl);
          dndForm.form.setFieldValue('imageId', file.response[0].imageId);
        },
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: 'Disable',
      dataIndex: 'disabled',
      inputType: 'boolean',
      width: '10%',
    },
  ];

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex items-center pb-4 justify-between">
        <div className="flex">
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Add Collection
          </Button>
        </div>
        <div className="flex">
          <div className="pr-2">
            <Button type="primary" disabled={dndForm.isPristine} onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <DndEditableTable
        records={records}
        columns={columns}
        dndForm={dndForm}
        antdTableProps={{ loading }}
      />
      <CreateCollectionModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};

export default CollectionList;
