/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Redirect } from '@reach/router';
import Cookies from 'js-cookie';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { setIsLoggedIn, setLoginError, setUserData } from '@/Redux/slices/AccountSlice';

import { postUserLoginAPI } from '@/Axios/api/account';
import { addAxiosToken } from '@/Axios/index';

import LoadingComponent from '@/Components/Loading';

import { storedCodeDecrypt } from '@/Utils/account';

let isFirstInit = true;

const PrivateRoute = ({ needAuth = true, component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPass, setIsPass] = useState<boolean>(false);
  const [isRedirectToHome, setIsRedirectToHome] = useState<boolean>(false);

  let isLogInSuccess = false;

  useEffect(() => {
    (async () => {
      const accessToken: string = Cookies.get('accessToken') || '';
      const ac: string = Cookies.get('ac') || '';
      const pd: string = Cookies.get('pd') || '';
      const hasAuthData = !!(accessToken !== '' && ac !== '' && pd !== '');

      if (isFirstInit) {
        if (hasAuthData) {
          try {
            const response = await postUserLoginAPI({
              grant_type: 'password',
              username: storedCodeDecrypt(ac),
              password: storedCodeDecrypt(pd),
            });

            const newAccessToken: string = response.data.access_token;
            const { username = '' } = response.data.principal;

            Cookies.set('accessToken', newAccessToken);
            addAxiosToken(newAccessToken);

            dispatch(setUserData({ username }));
            dispatch(setIsLoggedIn(true));
            isLogInSuccess = true;

            if (rest.path === '/login') {
              setIsRedirectToHome(true);
            }
          } catch (error) {
            console.error(error);
            Cookies.remove('accessToken');
            isLogInSuccess = false;
            setIsPass(false);
          }
        }

        isFirstInit = false;
      }

      if (needAuth) {
        setIsPass(isLoggedIn ? true : isLogInSuccess);
      } else {
        setIsPass(true);
      }

      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <>
        <Component {...rest} />;
        <LoadingComponent hasBackground={isFirstInit} />
      </>
    );
  }

  if (isRedirectToHome) {
    return <Redirect from="/login" to="/" noThrow />;
  }

  if (isPass) {
    return <Component {...rest} />;
  }

  dispatch(setLoginError(false));
  return <Redirect from="/" to="/login" noThrow />;
};

export default PrivateRoute;
