import AddCollectionPostModal from '@/Components/AddCollectionPostModal';
import DndEditableTable, {
  DndEditableTableColumnProps,
  DndEditableTableData,
} from '@/Components/dnd/DndEditableTable';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import useDndForm from '@/Hooks/useDndForm';
import { CollectionPost, CollectionPostsRequest } from '@/Interfaces/I_PostCollection';
import { UserRoleEnums } from '@/Interfaces/I_userRole';
import TagLabel from '@/Modules/tagLabel';
import UserRoleLabel from '@/Modules/userRoleLabel/UserRoleLabel';
import {
  deleteCollectionPost,
  getCollectionPosts,
  getCollections,
  updateCollectionPosts,
} from '@/Redux/slices/PostCollectionSlice';
import { Button, Popconfirm, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

interface OptionProps {
  label: string;
  value: string;
}

type PostRecord = CollectionPost & DndEditableTableData;

const CollectionPostList = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch();
  const { collections, currentPostCollection } = useAppSelector(
    (state) => state.postCollection.value,
  );
  const loading = useAppSelector((state) => state.postCollection.loading);

  const options: OptionProps[] = useMemo(() => {
    const updatedOptions = collections.map((c) => ({
      label: c.name,
      value: c.uuid,
      priority: c.priority,
    }));

    updatedOptions.sort((o1, o2) => o1.priority - o2.priority);
    return updatedOptions;
  }, [collections]);

  const records: PostRecord[] = useMemo(
    () =>
      currentPostCollection.posts
        .map((p) => ({
          ...p,
          key: p.priority.toString(),
        }))
        .sort((p1, p2) => p1.priority - p2.priority),
    [currentPostCollection],
  );

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCollectionUuid, setSelectedCollectionUuid] = useState(options[0]?.value);
  const dndForm = useDndForm(records);

  useEffect(() => {
    if (active && collections.length === 0) {
      dispatch(getCollections());
    }
  }, [active, collections, dispatch]);

  useEffect(() => {
    if (active && options.length > 0) {
      if (selectedCollectionUuid) {
        dispatch(getCollectionPosts(selectedCollectionUuid));
      } else {
        dispatch(getCollectionPosts(options[0].value));
      }
    }
  }, [active, dispatch, options, selectedCollectionUuid]);

  useEffect(() => {
    if (!dndForm.isPristine) {
      const updatedPostUuids = dndForm.currentValue.map((p) => p.uuid);
      const data: CollectionPostsRequest = {
        collectionUuid: selectedCollectionUuid,
        postUuids: updatedPostUuids,
      };

      dispatch(updateCollectionPosts(data));
    }
  }, [dispatch, dndForm.currentValue, dndForm.isPristine, selectedCollectionUuid]);

  const columns: DndEditableTableColumnProps<PostRecord>[] = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: 50,
    },
    {
      title: 'Post Title',
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: 'Post Uuid',
      dataIndex: 'uuid',
    },
    {
      title: 'KOC Category',
      dataIndex: 'kocCategory',
      width: 140,
      render: (category: string) => (
        <Select
          value={category}
          style={{ width: 120 }}
          options={[{ value: category, label: category }]}
          open={false}
        />
      ),
    },
    {
      title: 'Post Created',
      dataIndex: 'creationDate',
      width: '10%',
    },
    {
      title: 'User',
      dataIndex: 'userName',
      width: '10%',
    },
    {
      title: 'Pkey',
      dataIndex: 'userHybrisId',
      width: '10%',
    },
    {
      title: 'User Role',
      dataIndex: 'userRole',
      width: '10%',
      render: (userRole: string) => <UserRoleLabel userRoleStr={userRole as UserRoleEnums} />,
    },
    {
      title: 'Delete',
      dataIndex: 'uuid',
      width: '15%',
      render: (postUuid: string) => (
        <Popconfirm
          title="Confirm?"
          onConfirm={() =>
            dispatch(
              deleteCollectionPost({
                collectionUuid: selectedCollectionUuid,
                postUuids: [postUuid],
              }),
            )
          }
          okText="Yes"
          cancelText="No"
        >
          <TagLabel labelType="status" statusType="DISABLE" showDescription={false} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex items-center pb-4 justify-between">
        <Space>
          <span style={{ fontWeight: 700 }}>Select Collection:</span>
          <Select
            defaultValue={selectedCollectionUuid}
            style={{ width: 150 }}
            options={options}
            onChange={(value) => {
              setSelectedCollectionUuid(value);
              dispatch(getCollectionPosts(value));
            }}
          />
        </Space>
        <Button type="primary" onClick={() => setModalVisible(true)}>
          Add Post
        </Button>
      </div>
      <DndEditableTable
        editable={false}
        records={records}
        columns={columns}
        dndForm={dndForm}
        antdTableProps={{ loading }}
      />
      <AddCollectionPostModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};

export default CollectionPostList;
