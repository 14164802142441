/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
/* eslint-disable import/order */
import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';

import { addAxiosToken, clearAxiosToken } from '@/Axios/index';

import { AccessControl } from '@/Components/RoleBasedControl';

interface AccountSlice {
  isLoading: boolean;
  isLoggedIn: boolean;
  isLoginError: boolean;
  loginErrorMessage: string;
  user: {
    username: string;
  };
  accessControl: Array<AccessControl>;
  writeControl: Array<AccessControl>;
}

export const accountSlice = createSlice({
  name: 'accountSlice',
  initialState: {
    isLoading: false,
    isLoggedIn: false,
    isLoginError: false,
    loginErrorMessage: '',
    user: {
      username: '',
    },
    accessControl: [],
    writeControl: [],
  } as AccountSlice,
  reducers: {
    setLoading: (state, action) => {
      const assignState = state;
      assignState.isLoading = action.payload;
    },
    loginStart: (state, _action) => {
      const assignState = state;
      assignState.user.username = '';
      assignState.isLoggedIn = false;
      assignState.isLoginError = false;
    },
    loginSuccess: (state, action) => {
      const assignState = state;
      const { access_token, principal, remember } = action.payload;

      addAxiosToken(access_token);
      Cookies.set('accessToken', access_token);
      Cookies.set('ac', remember.username);
      Cookies.set('pd', remember.password);
      Cookies.set('isRemember', `${remember.saved}`);

      assignState.user.username = principal.username;
      assignState.isLoggedIn = true;
      navigate('/');
    },
    logoutStart: (state, _action) => {
      const assignState = state;
      assignState.isLoginError = false;
    },
    logoutSuccess: (state) => {
      const assignState = state;
      assignState.user.username = '';
      assignState.isLoggedIn = false;
      clearAxiosToken();

      Cookies.remove('accessToken');

      if (Cookies.get('isRemember') !== 'true') {
        Cookies.remove('ac');
        Cookies.remove('pd');
        Cookies.remove('isRemember');
      }

      navigate('/login');
    },
    setLoginError: (state, action) => {
      const assignState = state;
      assignState.isLoginError = true;
      assignState.loginErrorMessage =
        action.payload?.message || 'Cannot login. Please try again later.';
    },
    setIsLoggedIn: (state, action) => {
      const assignState = state;
      assignState.isLoggedIn = action.payload;
    },
    setUserData: (state, action) => {
      const assignState = state;
      const { username } = action.payload;
      assignState.user.username = username;
    },
  },
});

export const {
  setLoading,
  loginStart,
  loginSuccess,
  logoutStart,
  logoutSuccess,
  setIsLoggedIn,
  setLoginError,
  setUserData,
} = accountSlice.actions;

export default accountSlice.reducer;
