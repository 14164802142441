import { createSlice } from '@reduxjs/toolkit';

import { UserRolePagedData } from '@/Interfaces/I_userRole';

const userRoleSlice = createSlice({
  name: 'userRoleSlice',
  initialState: {
    value: {
      userRoles: {} as UserRolePagedData,
    },
    loading: false,
    error: '',
  },
  reducers: {
    getUserRole: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setUserRole: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, userRoles: action.payload.data };
      assignState.loading = false;
    },
    putUserRole: (state, action) => {},
  },
});

export const { getUserRole, setUserRole, putUserRole } = userRoleSlice.actions;

export default userRoleSlice.reducer;
