import { postParam } from '@/Interfaces/I_report';

import { customAxios } from '../index';

// get report
export const fetchReportPostAPI = (data: postParam) =>
  customAxios({
    url: 'hktv_koc/cms/post_reports/stats',
    method: 'GET',
    params: data,
  });

export default fetchReportPostAPI;
