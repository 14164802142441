import Affiliate from '@/Pages/Affiliate';

const AffiliateRoutes = [
  {
    breadcrumb: [{ path: '/affiliate', breadcrumbName: 'Affiliate' }],
    Component: Affiliate,
  },
];

export default AffiliateRoutes;
