import Collections from '@/Pages/Collection';

const CollectionRoutes = [
  {
    breadcrumb: [{ path: '/collections', breadcrumbName: 'Collection Module' }],
    Component: Collections,
  },
];

export default CollectionRoutes;
