import { PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { putMallCategoryParams } from '@/Interfaces/I_setting';

import {
  fail,
  getSettings,
  setSettings,
  updateMallCategorySetting,
  updateMallCategorySettingSuccess
} from '@/Slices/SettingSlice';

import { getSettingApi, putMallCategoryApi } from '@/Axios/api/setting';

function* handleGetSetting() {
  try {
    const data: AxiosResponse = yield call(getSettingApi);
    yield put(setSettings(data.data));
  } catch (err) {
    yield put(fail(err));
  }
}

function* handleUpdateMallCategorySetting(action: PayloadAction<putMallCategoryParams>) {
  try {
    yield call(putMallCategoryApi, action.payload);
    yield put(updateMallCategorySettingSuccess(action.payload));
  } catch (err) {
    yield put(fail(err));
  }
}

export function* watchGetSetting() {
  yield takeLatest(getSettings.type, handleGetSetting);
}

export function* watchhandleUpdateMallCategorySetting() {
  yield takeEvery(updateMallCategorySetting.type, handleUpdateMallCategorySetting);
}
