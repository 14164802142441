interface LazyLoadProps {
  /**
   *  the ref need to fix height and overflow-scroll
   */
  ref: React.MutableRefObject<HTMLParagraphElement | null>;
  /**
   *  0<= height <=1 is percentage , 1 = 100% = Scroll to the bottom 0
   */
  height?: number;
  /**
   *  scroll to height percentage, run this event
   */
  loadMoreCallback?: () => void;
  /**
   *  can set a useEffect avoid repeat trigger in a short time
   */
  delay?: boolean;
  setDelay?: (isDelay: boolean) => void;
}

const lazyLoad = ({
  ref,
  height = 1,
  loadMoreCallback = () => {},
  delay = false,
  setDelay = () => {},
}: LazyLoadProps) => {
  if (ref.current) {
    const scrollDistance = ref.current.scrollHeight - ref.current.clientHeight;
    const triggerScrollCondition = ref.current.scrollTop >= scrollDistance * height;

    if (triggerScrollCondition && !delay) {
      loadMoreCallback();
      setDelay(true);
    }
  }
};

export default lazyLoad;
