import React, { useEffect, useRef, useState } from 'react';

import { Button, Spin } from 'antd';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getReportRecord, getUserReportRecord } from '@/Redux/slices/ModalSlice';

import Table from '@/Modules/table';
import TagLabel from '@/Modules/tagLabel';

import lazyLoad from '@/Utils/lazyLoad';
import { timeTranslate } from '@/Utils/timeTranslate';

interface ReportRecordProps {
  onDetailClick: (reportId: number) => void;
  onRowClick: (record: any, recordIndex?: number) => void;
  postUuid?: string;
}

const ReportRecord = ({ onDetailClick, onRowClick, postUuid }: ReportRecordProps) => {
  const recordColumns = [
    {
      title: 'Handling Time',
      dataIndex: 'handlingTime',
      key: 'handlingTime',
      width: '19.18%',
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
      width: '22.28%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (text: any) =>
        text !== '' ? (
          <div className="flex justify-center p-0">
            <TagLabel labelType="status" statusType={text} />
          </div>
        ) : (
          ''
        ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      className: 'pr-0',
      render: (item: any) => (
        <div className="flex justify-between items-center ">
          <p className="mb-0">{item.text}</p>
          <Button
            type="link"
            className="blue-600 pr-0"
            onClick={() => {
              if (item.reportId) {
                dispatch(getUserReportRecord({ post_report_handle_id: item.reportId }));
              } else {
                dispatch(getUserReportRecord({ post_uuid: postUuid }));
              }
              onDetailClick(item.reportId);
            }}
          >
            Detail
          </Button>
        </div>
      ),
    },
  ];
  const dispatch = useAppDispatch();
  const [delay, setDelay] = useState(false);
  const mainRef = useRef<HTMLParagraphElement | null>(null);
  const reportAPIData = useAppSelector((state) => state.modal.value.reportRecord.data);
  const pagination = useAppSelector((state) => state.modal.value.reportRecord.pagination);
  const loading = useAppSelector((state) => state.modal.loading);

  useEffect(() => {
    if (!loading && pagination.page === 0) {
      mainRef.current?.scrollTo(0, 0);
    }
  }, [loading, pagination]);

  useEffect(() => {
    const timer = setTimeout(() => setDelay(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  const pendingData = [
    {
      key: postUuid as string | number,
      handlingTime: 'Pending',
      admin: '',
      action: '',
      reason: {},
    },
  ];

  return !loading && pendingData ? (
    <div
      className="p-6 h-c-545 overflow-y-scroll"
      ref={mainRef}
      onScroll={() => {
        lazyLoad({
          height: 0.8,
          ref: mainRef,
          delay,
          setDelay,
          loadMoreCallback: () => {
            if (pagination.hasNext) {
              dispatch(
                getReportRecord({
                  post_uuid: postUuid,
                  page_no: pagination.page + 1,
                  append: true,
                }),
              );
            }
          },
        });
      }}
    >
      <Table
        columns={recordColumns}
        dataSource={pendingData.concat(
          reportAPIData.map((item) => ({
            key: item.id as string | number,
            handlingTime: timeTranslate(item.creationDate, true),
            admin: item.userName,
            action: item.actionType,
            reason: {
              text: `${item.reasons.join(', ')}${
                item.reasons.length > 0 && item.otherReason !== '' ? ',' : ''
              }${item.otherReason}`,
              reportId: item.id,
            },
          })),
        )}
        pagination={false}
        onRow={(record, recordIndex) => ({
          onClick: () => {
            onRowClick(record, recordIndex);
          },
        })}
      />
    </div>
  ) : (
    <div className="h-c-545 flex justify-center items-center">
      <Spin size="large" />
    </div>
  );
};

export default ReportRecord;
