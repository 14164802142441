/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './tab.scss';

import { TabPaneProps as TabsPaneProps, Tabs as TabsAntd, TabsProps } from 'antd';

const { TabPane } = TabsAntd;
interface TabProps extends TabsProps {
  initialPanes?: TabPaneProps[];
}
interface TabPaneProps extends TabsPaneProps {
  title: string | React.ReactNode;
  key: string | number;
  content: React.ReactNode;
}

const Tab = ({ initialPanes, ...props }: TabProps) => (
  <div className="tab--root">
    <TabsAntd size="large" {...props}>
      {initialPanes?.map((pane) => (
        <TabPane {...initialPanes} tab={pane.title} key={pane.key} >
          {pane.content}
        </TabPane>
      ))}
    </TabsAntd>
  </div>
);

export default Tab;
