import React, { useState } from 'react';

import { Button, DatePicker, InputNumber, message, Modal } from 'antd';

import { useAppDispatch } from '@/Hooks/useAppRedux';

import { postAffiliateBudget } from '@/Redux/slices/AffiliateSlice';

const AffiliateCreateBudgetModal = ({
  isCreateBudgetVisible,
  setIsCreateBudgetVisible,
  postType,
}: any) => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const MINIMUM_BUDGET_AMOUNT = 1;

  const [budgetData, setBudgetData] = useState({
    postType,
    allocateAmount: MINIMUM_BUDGET_AMOUNT,
    periodFrom: 0,
    periodTo: 0,
  });

  const error = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
    });
  };

  return (
    <Modal
      title="Add New Affiliate Budget"
      visible={isCreateBudgetVisible}
      onCancel={() => setIsCreateBudgetVisible(false)}
      width={856}
      bodyStyle={{ padding: 0 }}
      footer={null}
    >
      <div className="h-auto bg-white p-6 pb-2">
        {contextHolder}
        <div className="mb-2">
          <span>Allocate Amount:</span>
          <InputNumber
            style={{ width: 200 }}
            min={MINIMUM_BUDGET_AMOUNT}
            defaultValue={MINIMUM_BUDGET_AMOUNT}
            onChange={(value: number | null) => {
              const newData = { ...budgetData, allocateAmount: value ?? MINIMUM_BUDGET_AMOUNT };
              setBudgetData(newData);
            }}
          />
        </div>

        <div className="mb-2">
          <span>Post Type: {postType === 'NORMAL' ? 'KOC' : 'VOD'}</span>
        </div>

        <div className="mb-2">
          <span>Period From:</span>
          <DatePicker
            inputReadOnly
            onChange={(date) => {
              const newData = {
                ...budgetData,
                periodFrom: date
                  ? Math.floor(new Date(date.startOf('day').toISOString()).getTime() / 1000)
                  : 0,
              };
              setBudgetData(newData);
            }}
          />
        </div>

        <div className="mb-2">
          <span>Period To:</span>
          <DatePicker
            inputReadOnly
            onChange={(date) => {
              const newData = {
                ...budgetData,
                periodTo: date
                  ? Math.floor(new Date(date.endOf('day').toISOString()).getTime() / 1000)
                  : 0,
              };
              setBudgetData(newData);
            }}
          />
        </div>

        <div className="flex justify-end pt-6">
          <Button
            type="primary"
            onClick={() => {
              if (budgetData.periodTo > budgetData.periodFrom) {
                dispatch(postAffiliateBudget(budgetData));
                setIsCreateBudgetVisible(false);
                return;
              }
              error('Period To is before Period From. Please change.');
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AffiliateCreateBudgetModal;
