import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';

const customFetch = (uri: string, options: any) =>
  fetch(`https://aprd-dev.gcp.hkmpcl.com.hk/${uri}/graphql`, options);

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('accessToken') || '';
  return {
    headers: {
      ...headers,
      'Accept-Language': 'en',
      authorization: token ? `Bearer ${token},cms` : '',
    },
  };
});

const link = createHttpLink({
  fetch: customFetch,
  uri: '',
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

export default client;
