import { customAxios } from '../index';

const url = 'hktv_koc/cms/media';

const deleteKocImage = (id: string[]) => customAxios.post(`${url}/deletePhoto`, id);

const uploadKocPhotoUrl = `${url}/uploadPhoto`;

const uploadKocCampaignPhotoUrl = `${url}/upload-campaign-photo`;

// use existing campaign upload image url, update its path when campaign module refactoring
const uploadPostCollectionImageUrl = `${url}/upload-campaign-photo`;

export {
  deleteKocImage,
  uploadKocPhotoUrl,
  uploadKocCampaignPhotoUrl,
  uploadPostCollectionImageUrl,
};
