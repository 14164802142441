/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';

import { Alert, Button, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as XLSX from 'xlsx';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getCensored, updateCensored } from '@/Slices/CensorshipSlice';

const Censorship = () => {
  const dispatch = useAppDispatch();
  const { censored, updateCode } = useAppSelector((state) => state.censorship.value);
  // base setting
  const [importData, setImportData] = useState<string>('');
  const [alertType, setAlertType] = useState<'success' | 'error' | undefined>(undefined);
  const fileRef = useRef<HTMLInputElement>(null);

  // api
  useEffect(() => {
    dispatch(getCensored());
  }, [dispatch]);
  useEffect(() => {
    setImportData(censored.toString().replaceAll(',', '\n'));
  }, [censored]);
  useEffect(() => {
    if (updateCode) {
      if (updateCode === 200) {
        setAlertType('success');
      }
      if (updateCode !== 200 && updateCode !== 0) {
        setAlertType('error');
      }
    }
  }, [updateCode]);

  // function
  const importFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) return;
    const fileType = e.target.value.split('.').pop();
    const reader = new FileReader();
    if (fileType === 'txt') {
      // for .txt
      reader.onload = (event) => {
        const text = event?.target?.result || '';
        setImportData((text as string).replaceAll('\r\n', '\n'));
      };
      reader.readAsText(e.target.files[0]);
    } else if (fileType === 'csv') {
      // for .csv
      reader.onload = () => {
        const csvData = (reader.result as string).split(/\r\n|\n/);
        const data = csvData.map((row) => {
          const columns = row.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g) as string[];
          return columns.filter((word) => word !== ',');
        });
        const finalData = data.toString().replaceAll(',', '\n');
        setImportData(finalData);
      };
      reader.readAsText(e.target.files[0]);
    } else if (fileType === 'xlsx') {
      // for .xlsx
      reader.onload = (event) => {
        try {
          const result = event?.target?.result;
          const workbook = XLSX.read(result, { type: 'binary' });
          let data: string[] = [];
          for (const sheet in workbook.Sheets) {
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              // 利用 sheet_to_json excel 轉成 json
              data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            }
          }
          const dataList = data.map((item) => Object.values(item)[0]);
          const finalData = dataList.toString().replaceAll(',', '\n');
          setImportData(finalData);
        } catch (error) {
          console.log('xlsx error:', error);
        }
      };
      reader.readAsBinaryString(e.target.files[0]);
    } else {
      message.error('錯誤檔案格式');
    }
  };

  const exportCsv = () => {
    const fileName = 'download.csv';
    // 匯出的檔名
    const blob = new Blob([`\uFEFF${importData}`], {
      type: 'text/csv;charset=gb2312;',
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = href;
    link.download = fileName;
    link.click();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!alertType && (
        <Alert
          message={
            alertType === 'success' ? 'Saved successfully.' : 'Saved failed. Please try again.'
          }
          type={alertType}
          className="mb-6"
          showIcon
        />
      )}
      <div className="flex justify-end">
        <Button onClick={() => fileRef.current?.click()}>Import data</Button>
        <Button disabled={!importData} style={{ margin: '0 8px' }} onClick={exportCsv}>
          Generate CSV
        </Button>
        <Button
          type="primary"
          disabled={!importData}
          onClick={() => {
            dispatch(updateCensored(importData.split('\n')));
          }}
        >
          Save
        </Button>
        <input
          type="file"
          className="hidden"
          ref={fileRef}
          accept=".xlsx, .txt, .csv"
          onChange={(e) => {
            importFile(e);
          }}
        />
      </div>
      <div className="bg-white my-6 py-4 px-6">
        <TextArea
          value={importData.toString()}
          onChange={(e) => {
            setImportData(e.target.value);
          }}
          placeholder="Please click “import data” or fill words into this frame."
          style={{ height: '500px', padding: '16px 24px' }}
        />
      </div>
      {/* remind */}
      <div className="pb-16">
        <ul className="list-none pl-0">
          <li>* One word per row and filled into the above frame.</li>
          <li>
            * "<span className="font-medium">Import data</span>" would replace all the data in the
            frame.
          </li>
          <li>
            * If you <span className="font-medium">leave this page</span>, the data changes will not
            be saved automatically. So please remember to click "
            <span className="font-medium">Save</span>" before you leave.
          </li>
        </ul>
      </div>
    </>
  );
};
export default Censorship;
