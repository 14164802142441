import Category from '../pages/Setting/Category';
import RebateTnc from '../pages/Setting/RebateTnc';

const SettingRoutes = [
  {
    breadcrumb: [{ path: '/setting/category', breadcrumbName: 'Category' }],
    Component: Category,
  },
  {
    breadcrumb: [{ path: '/setting/rebate-tnc', breadcrumbName: 'Rebate TNC' }],
    Component: RebateTnc,
  },
];

export default SettingRoutes;
