import { Button, Modal } from "antd";
import React from 'react'

interface confirmationModalProps {
  title: React.ReactElement
  body:  React.ReactElement
  onConfirm: () => void;
  setConfirmationModalShow:  (value: boolean) => void;
  isModalVisible: boolean;
}

interface FooterProps {
  onConfirmClick : () => void;
  onCancelClick :  () => void;
}

const Footer = ({onConfirmClick, onCancelClick}: FooterProps) => (
  <div className="flex flex-row">
    <Button className="text-sky-500 border-sky-500" onClick={onConfirmClick}>Confirm</Button>
    <Button danger onClick={onCancelClick}>cancel</Button>
  </div>
)

const ConfirmationModal = ({title, body, onConfirm, isModalVisible, setConfirmationModalShow}: confirmationModalProps) => (
  <div>
    <Modal
    title={title}
    open={isModalVisible}
    footer={<Footer onConfirmClick={onConfirm} onCancelClick={() => setConfirmationModalShow(false)} />}
    onCancel={() => setConfirmationModalShow(false)}
    >
      {body}
    </Modal>
  </div>
)


export default ConfirmationModal;